import tenantApi from '@api';
import React, { useState } from 'react';
import { ReactAudioContainer } from '../styled';
import { Button, Flex, notification, Popover, Skeleton } from '../../common';
import { t } from 'i18next';
import { useLazyGetLeadCallRecordingQuery } from '../../../apis/leads';
const CallRecording = (props) => {
  const { recording_uuid } = props;
  const [popupVisible, setPopupVisible] = useState(false);
  const [callURL, setCallURL] = useState(null);
  const [getLeadCallRecording, { isFetching: loading }] = useLazyGetLeadCallRecordingQuery();

  const getCallRecording = async (e) => {
    e.stopPropagation();
    const response = await getLeadCallRecording({ recording_uuid });
    if (response) {
      if (response?.error) {
        notification.error(response?.error);
        setPopupVisible(false);
      } else {
        setCallURL(response?.data);
      }
    }
  };

  const renderCallPlaybackSkeleton = () => {
    return (
      <Flex vertical gap="2px">
        {[1].map(() => (
          <>
            <Skeleton style={{ height: '30px', width: '300px' }} />
          </>
        ))}
      </Flex>
    );
  };
  const renderCallPlayback = () => {
    return <>{loading ? renderCallPlaybackSkeleton() : <ReactAudioContainer src={callURL} controls />}</>;
  };

  return (
    <>
      <Popover
        placement="bottomLeft"
        content={renderCallPlayback()}
        action="click"
        open={popupVisible}
        onOpenChange={(visible) => setPopupVisible(visible)}
      >
        <Button
          size="small"
          type="link"
          icon="MdPlayCircleOutline"
          className="p-0"
          onClick={getCallRecording}
          style={{ justifyContent: 'start', '--ant-control-height-sm': '20px' }}
        >
          {t('Play Recording')}
        </Button>
      </Popover>
    </>
  );
};
export default CallRecording;
