import { t } from 'i18next';
import { strings } from '../../../constants/strings';
import Algolia from '../../../services/algolia';
import tenantConstants from '@constants';
import { emailValidationYup, phoneValidationYup, stringValidationYup } from '../../../helpers';

export const agencyStaffUserFormFields = (userId = null, cities) => {
  return {
    name: {
      type: 'input',
      value: '',
      validation: () => stringValidationYup(t(strings?.vm?.name)),
      props: {
        label: 'Name',
        placeholder: 'Enter Name',
        maxLength: 80,
      },
    },
    email: {
      type: 'input',
      value: '',
      validation: () => emailValidationYup(),
      props: {
        label: 'Email',
        placeholder: 'Enter Email',
        maxLength: 300,
        disabled: !!userId ? true : false,
      },
    },
    ...(!userId && {
      password: {
        type: 'input',
        value: '',
        show: false,
        validation: () => stringValidationYup(t(strings.vm.password)),
        props: {
          label: 'Password',
          placeholder: 'Enter Password',
          maxLength: 300,
        },
      },
    }),
    mobile: {
      type: 'phone-input',
      value: '',
      validation: () => phoneValidationYup(true),
      props: {
        label: 'Mobile',
        placeholder: 'Enter Mobile Number',
        defaultCountry: 'PK',
      },
    },

    landline: {
      type: 'phone-input',
      value: '',
      validation: () => phoneValidationYup(false),
      props: {
        label: 'Landline',
        placeholder: 'Enter Landline',
        defaultCountry: 'PK',
      },
    },
    city: {
      type: 'select',
      validation: () => stringValidationYup(t(strings.vm.city)).nullable(),
      props: {
        label: tenantConstants.USER_LOCATIONS.label,
        placeholder: `Select  ${tenantConstants.USER_LOCATIONS.label}`,
        options: cities,
      },
    },
    address: {
      type: 'input',
      value: '',
      validation: () => stringValidationYup(strings.vm.address),
      props: {
        label: 'Address',
        placeholder: 'Enter Address',
        maxLength: 250,
      },
    },

    image: {
      type: 'image-select',
      value: [],
      validation: () => null,
      props: {
        label: 'Upload a picture',
        placeholder: 'Browse and Upload',
        multi: false,
        attachmentType: 'user_profile',
      },
    },
  };
};
