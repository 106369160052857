import { trackEventGA4 } from './gtmEventHandler';

import {
  PAGE_TYPES,
  INTERACTED_FROM,
  WEBSITE_SECTIONS,
  getCommonParams,
  PAGE_GROUPS,
  PAGE_SECTIONS,
  STRINGS,
  statusMap,
  EVENT_NAMES,
  EVENT_CATEGORIES,
  getPageType,
} from './constants';

export const addPropertyEvent = (user, currentPathSlug, isSideMenu) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.ADD_PROPERTY_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: isSideMenu ? PAGE_TYPES.SIDE_MENU : currentPathSlug,
      interacted_from: isSideMenu
        ? INTERACTED_FROM.SIDE_MENU
        : user.is_package_user
          ? INTERACTED_FROM.HEADER_PROFOLIO
          : INTERACTED_FROM.HEADER_LITE,
      ...commonParams,
    },
  });
};

export const regaAdValidationEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.REGA_AD_VALIDATION_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      ...commonParams,
    },
  });
};

export const pageViewPostListingsDetailEvent = (user, is_new) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: is_new ? PAGE_SECTIONS.NEW : PAGE_SECTIONS.EDIT,
      posting_reason: is_new ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const regaInformationClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.REGA_DETAILS,
      ...commonParams,
    },
  });
};

export const regaQRCodeClickEvent = (user, is_qr, license_info) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: is_qr ? PAGE_SECTIONS.REGA_QR : PAGE_SECTIONS.REGA_LINK,
      ad_license_number: license_info.ad_license_number,
      ...commonParams,
    },
  });
};
export const changeLocationClickEvent = (user, formValues, is_new) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.MAP,
      property_type: formValues?.listing_type?.title
        ? formValues?.listing_type?.title
        : formValues?.property_type_label,
      posting_reason: is_new ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const changeLocationConfirmEvent = (user, propertyType, is_new) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.MAP_PIN_SELECTED_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      property_type: propertyType ? propertyType?.title : '',
      posting_reason: is_new ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const generateTitleClickEvent = (user, response, formValues) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CONTENT_GENERATION_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.TITLE,
      status: response.error ? STRINGS.ERROR : STRINGS.SUCCESS,
      property_type: formValues?.listing_type?.title || '',
      posting_reason: !formValues.is_posted ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const generateDescriptionClickEvent = (user, response, formValues) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CONTENT_GENERATION_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.DESCRIPTION,
      status: response.error ? STRINGS.ERROR : STRINGS.SUCCESS,
      property_type: formValues.listing_type?.title || '',
      posting_reason: !formValues.is_posted ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const getTopUpClickEvent = (user, credits) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.GET_TOPUP_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      get_package_type: credits,
      ...commonParams,
    },
  });
};

export const suggestCreditsClickEvent = (user, credits) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      get_package_type: credits,
      ...commonParams,
    },
  });
};

export const emailButtonClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.ACCOUNT_MANAGER,
      page_section: PAGE_SECTIONS.EMAIL,
      ...commonParams,
    },
  });
};

export const whatsappButtonClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.ACCOUNT_MANAGER,
      page_section: PAGE_SECTIONS.WHATSAPP,
      ...commonParams,
    },
  });
};

export const phoneCallButtonClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.ACCOUNT_MANAGER,
      page_section: PAGE_SECTIONS.CALL,
      ...commonParams,
    },
  });
};

export const pageViewDashboardEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.DASHBOARD,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewCreditUsageEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.CREDIT_USAGE,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewReportSummaryEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.SUMMARY,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewLeadsReportEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.LEADS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewListingReportEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.LISTINGS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewAgencyStaffEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.AGENCY,
      page_type: PAGE_TYPES.AGENCY_STAFF,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewAgencySettingEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.AGENCY,
      page_type: PAGE_TYPES.AGENCY_SETTINGS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewUserSettingEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.AGENCY,
      page_type: PAGE_TYPES.USER_SETTINGS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewMyListingEvent = (user, currentStatusID) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      interacted_from: user.is_package_user ? INTERACTED_FROM.HEADER_PROFOLIO : INTERACTED_FROM.HEADER_LITE,
      ...commonParams,
    },
  });
};

export const helpCenterClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.HELP_CENTRE_CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.HELP_CENTRE,
      ...commonParams,
    },
  });
};

export const profolioButtonClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.PROFOLIO,
      ...commonParams,
    },
  });
};

export const regaComplianceClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.REGA_COMPLIANCE,
      ...commonParams,
    },
  });
};

export const faqSectionClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.FAQ,
      ...commonParams,
    },
  });
};
export const pageViewPackageScreenEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};
export const packageMonthClickEvent = (user, packageDuration) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.PACKAGE_DURATION,
      current_package_duration: packageDuration,
      ...commonParams,
    },
  });
};
export const packageSelectEvent = (user, currentPackage) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: currentPackage ? EVENT_NAMES.CLICK_UPGRADE_ACT : EVENT_NAMES.GET_PACKAGE_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.PACKAGES,
      ...commonParams,
    },
  });
};
export const upgradePackageConfirmClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.PACKAGES,
      ...commonParams,
    },
  });
};
//re-check once upgrade is live
export const upgradeClickEvent = (user, upgrade, listingType, purpose, city, area) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.UPGRADE_CLICK,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: PAGE_TYPES.ACTIVE,
      page_section: upgrade,
      listing_type: listingType,
      listing_purpose: purpose,
      city: city,
      area: area,
      ...commonParams,
    },
  });
};

export const imageUploadSuccessEvent = (user, response, is_new, formValues) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.IMAGE_UPLOAD_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      status: response.status === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      value: response.status === 200 ? null : response.statusText,
      property_type: formValues?.listing_type?.title,
      posting_reason: is_new ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};
export const pageViewUpsellEvent = (user, listingData) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.UPSELL,
      listing_disposition: listingData.disposition.name,
      listing_purpose: listingData.listing_purpose.title,
      city: listingData.city,
      ...commonParams,
    },
  });
};
export const upgradeButtonUpsellClickEvent = (user, response, listingData, formValues, selectedProducts) => {
  const commonParams = getCommonParams(user);

  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.UPSELL_SUBMIT_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.UPSELL,
      status: response.status === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      listing_disposition: listingData.disposition.name,
      listing_type: selectedProducts[0],
      photography_service: !!formValues['photography-service'] ? 'yes' : 'no',
      videograhy_service: !!formValues['videography-service'] ? 'yes' : 'no',
      drone_service: !!formValues['drone-footage-service'] ? 'yes' : 'no',
      ...commonParams,
    },
  });
};

export const postListingClickEvent = (user, responseStatus, listingData, autoRenew) => {
  const commonParams = getCommonParams(user);
  const featuresCount = listingData?.feature_and_amenities
    ? Object.values(listingData?.feature_and_amenities)?.filter((value) => value === true).length
    : 0;
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.SUBMIT_LISTING_ACT,
    is_non_interaction: false,
    params: {
      pagetype: PAGE_TYPES.DETAILS,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      status: responseStatus === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      property_type: listingData.listing_type.title,
      listing_disposition: listingData.disposition.name,
      furnished: listingData.is_furnished,
      bathrooms_selected: listingData.bathrooms,
      bedrooms_selected: listingData.bedrooms,
      city: listingData.location.title,
      purpose: listingData.listing_purpose.title,
      rental_frequency: listingData.rent_frequency,
      images_added: listingData.property_images.length,
      price: listingData.price,
      listing_status: listingData.status.name,
      auto_renew: autoRenew,
      area: listingData?.area_unit?.value ? listingData?.area_unit?.value : listingData?.area,
      amenities_added: featuresCount,
      ...commonParams,
    },
  });
};

export const pageViewCheckoutEvent = (user, interactedFrom, cartData, disposition, status, payment_method) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.CHECKOUT,
      payment_method: payment_method,
      interacted_from: interactedFrom,
      value: cartData?.total,
      listing_status: status,
      listing_disposition: disposition,
      ...commonParams,
    },
  });
};

export const checkoutPayClickEvent = (user, interactedFrom, cartData, disposition, status) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.PAY_CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.CHECKOUT,
      status: response.status === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      value: errorValue,
      listing_type: listingType,
      listing_status: listingData.status.name,
      listing_disposition: listingData.disposition.name,
      listing_purpose: listingData.listing_purpose.title,
      payment_method: paymentMethod,
      ...commonParams,
    },
  });
};

export const nafathButtonClickEvent = (user, response, errorValue) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.VERIFY_NAFATH_CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.NAFATH,
      status: response.status === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      value: errorValue,
      ...commonParams,
    },
  });
};

export const upgradeButtonClickEvent = (user, response, errorValue) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.UPGRADE_CLICK,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: PAGE_TYPES.ACTIVE,
      page_section: upgradeType,
      listing_type: listingType,
      purpose: ListingPurpose,
      city: city,
      area: area,
      ...commonParams,
    },
  });
};

export const actionButtonQualityWidgetClickEvent = (user, currentStatusID, actionType, props) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      page_section: PAGE_SECTIONS.QUALITY,
      value: actionType,
      amenities_added: props.features_selected,
      images_added: props.unique_images,
      ...commonParams,
    },
  });
};

export const actionButtonListingsClickEvent = (user, currentStatusID, pageSection) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.UPGRADE_CLICK,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      pageSection: pageSection,
      ...commonParams,
    },
  });
};

export const hoverListingQualityDonut = (user, currentStatusID, qualityScore) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.QUALITY_HOVER,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      value: qualityScore,
      ...commonParams,
    },
  });
};

export const deleteListingConfirmEvent = (user, currentStatusID, response, deletionReason) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.DELETE_LISTING,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      page_section: PAGE_SECTIONS.DELETE,
      status: response.error ? STRINGS.ERROR : STRINGS.SUCCESS,
      value: deletionReason,
      ...commonParams,
    },
  });
};

export const downloadAppButtonClick = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    event: EVENT_NAMES.DOWNLOAD_APP_CLICK,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.APP_REDIRECT,
      page_type: PAGE_TYPES.DOWNLOAD_BUTTON,
      ...commonParams,
    },
  });
};
export const downloadAppActionEvent = (user, pageSection) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.APP_REDIRECT,
    event: EVENT_NAMES.DOWNLOAD_APP_ACTION,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.APP_REDIRECT,
      page_type: STRINGS.DOWNLOAD_BUTTON,
      page_section: pageSection,
      ...commonParams,
    },
  });
};
export const pageViewInboxEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.LEADS,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      page_group: PAGE_GROUPS.LEADS,
      page_type: PAGE_TYPES.INBOX,
      ...commonParams,
    },
  });
};

export const completeYourProfileClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PROFILE_COMPLETION_CAT,
    event: EVENT_NAMES.COMPLETE_PROFILE_CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.DASHBOARD,
      page_type: PAGE_TYPES.DASHBOARD,
      ...commonParams,
    },
  });
};

export const whyIsProfileCompletionImportantClickEvent = (user, isSettingsPage) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PROFILE_COMPLETION_CAT,
    event: EVENT_NAMES.WHY_IS_PROFILE_COMPLETION_IMPORTANT_ACT,
    is_non_interaction: false,
    params: {
      page_group: isSettingsPage ? PAGE_GROUPS.SETTINGS : PAGE_GROUPS.DASHBOARD,
      page_type: isSettingsPage ? PAGE_TYPES.USER_SETTINGS : PAGE_TYPES.DASHBOARD,
      ...commonParams,
    },
  });
};

export const profileCompletionPopUpCancelEvent = (user, route) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PROFILE_COMPLETION_CAT,
    event: EVENT_NAMES.PROFILE_COMPLETION_POP_UP_CANCEL_ACT,
    is_non_interaction: false,
    params: {
      page_type: getPageType(route),
      ...commonParams,
    },
  });
};

export const submitListingEvent = (user, formValues, status, value) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.SUBMIT_LISTING_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.LEADS,
      page_type: PAGE_TYPES.INBOX,
      ...commonParams,
    },
  });
};

export const mlUpgradesEvent = (user, listing, value) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.UPGRADE_CLICK,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: PAGE_TYPES.ACTIVE,
      page_section: value?.orderSummaryTitle,
      value: value.slug,
      purpose: listing?.purpose,
      property_type: listing?.propertyTitle,
      area: listing?.area,
      city: listing?.location.title,
      package_type: user.package?.slug,
      is_agency: user.agency ? true : false,
      agency_id: user.agency?.id,
      agency_name: user.agency?.name,
      ...commonParams,
    },
  });
};

export const submitUpgradePopupClickEvent = (user, errorValue, listing, applicableProduct) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.CONFIRM_CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: PAGE_TYPES.ACTIVE,
      page_section: applicableProduct?.appliedTitle,
      status: !errorValue ? STRINGS.SUCCESS : STRINGS.ERROR,
      errorValue,
      area: listing?.area,
      purpose: listing?.purpose,
      city: listing?.location?.title,
      price: listing?.price?.value,
      ...commonParams,
    },
  });
};

export const adLicenseVerificationClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.AD_LICENSE_VER,
      ...commonParams,
    },
  });
};
export const crVerificationContinueClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.CR_VERIFICATION,
      ...commonParams,
    },
  });
};

export const customEventClick = (user, customDetails) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.CHOOSE_LISTING_TYPE,
      item_id: customDetails.itemId,
      purpose: customDetails.additionalInfo,
      ...commonParams,
    },
  });
};

export const nationalIdVerificationClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.NATIONAL_ID_VERIFICATION,
      ...commonParams,
    },
  });
};

export const verifyFALLicenseClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.CHOOSE_FAL_LICENSE,
      ...commonParams,
    },
  });
};
export const payButtonUpgradeClickEvent = (user, paymentMethod, status, productDetails) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.CHECKOUT,
    event: EVENT_NAMES.PAY_CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: productDetails?.listing_id ? PAGE_GROUPS.MANAGE_LISTING : PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.CHECKOUT,
      payment_method: paymentMethod,
      status: status ? STRINGS.SUCCESS : STRINGS.ERROR,
      value: productDetails?.total,
      package_type: productDetails[0]?.title,
      current_package: user?.package ? user?.package?.slug : null,
      listing_id: productDetails?.listing_id,
      ...commonParams,
    },
  });
};

export const startYourJourneyClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.TRU_BROKER_CAT,
    event: EVENT_NAMES.START_TRUBROKER_JOURNEY_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.TRU_BROKER,
      page_type: PAGE_TYPES.DASHBOARD,
      ...commonParams,
    },
  });
};

export const truBrokerLearnMoreClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.TRU_BROKER_CAT,
    event: EVENT_NAMES.TRUBROKER_LEARN_MORE_CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.TRU_BROKER,
      page_type: PAGE_TYPES.DASHBOARD,
      ...commonParams,
    },
  });
};

export const howToEarnTruPointsClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.TRU_BROKER_CAT,
    event: EVENT_NAMES.TRUBROKER_HOW_TO_EARN_TRUPOINTS_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.TRU_BROKER,
      page_type: PAGE_TYPES.DASHBOARD,
      ...commonParams,
    },
  });
};

export const viewLeaderBoardClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.TRU_BROKER_CAT,
    event: EVENT_NAMES.TRUBROKER_VIEW_LEADERBOARD_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.TRU_BROKER,
      page_type: PAGE_TYPES.DASHBOARD,
      ...commonParams,
    },
  });
};

export const truBrokerSuccessPopUpCancelClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.TRU_BROKER_CAT,
    event: EVENT_NAMES.TRUBROKER_CANCEL_CLICK_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.TRU_BROKER,
      page_type: PAGE_TYPES.DASHBOARD,
      ...commonParams,
    },
  });
};

export const selectListingTypeClickEvent = (user, listingType) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.SELECT_CATEGORY_ACT,
    is_non_interaction: false,
    params: {
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.ADD_PROPERTY_FORM,
      purpose: listingType,
      ...commonParams,
    },
  });
};
