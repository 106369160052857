import { Area } from './table-components/area';
import { AvatarName } from './table-components/avatar';
import { Date } from './table-components/date';
import { ExpandableMessage } from './table-components/expandable-message';
import { ExpiryRenewal } from './table-components/expiry-renewal';
import { Health } from './table-components/health';
import { Img } from './table-components/image';
import { ListingPurpose } from './table-components/listing-purpose';
import { OrderActions } from './table-components/order-actions';
import { OrderStatus } from './table-components/order-status';
import { Platform } from './table-components/platform';
import { PlatformListingActions } from './table-components/platform-listing-actions';
import { PlatformStatus } from './table-components/platforms-status';
import { Price } from './table-components/price';
import { Products } from './table-components/products';
import { ListingStats } from './table-components/listing-stats';
import { StaffDetails } from './table-components/staff-details';
import { Stats } from './table-components/stats';
import { String } from './table-components/string';
import { TableListingActions } from './table-components/table-listing-actions';
import { TableNumber } from './table-components/table-number';
import { UserRowActions } from './table-components/user-row-actions';
import TableActions from './table-actions/table-actions';
import { SourceContent } from './table-components/source-content';
import { ListingDetailCompact } from './table-components/listing-detail-compact';
import { DateTime } from './table-components/date-time';
import { LeadInfo } from './table-components/lead-info';
import { TaskDetail } from './table-components/task-detail';
import { TaskNotes } from './table-components/task-notes';
import CallRecording from './table-components/call-recording';
import { TruBrokerScore } from './table-components/tru-broker-score';
import ListingRowActions from './table-actions/listing-row-actions';
import PlatformListing from './table-components/platform-listings';

export default {
  TaskNotes,
  AvatarName,
  Image: Img,
  String,
  Number: TableNumber,
  Date,
  PlatformStatus,
  Price,
  Area,
  Health,
  Platform,
  PlatformListingActions,
  TableListingActions,
  UserRowActions,
  Stats,
  OrderActions,
  OrderStatus,
  Products,
  ListingPurpose,
  ExpiryRenewal,
  ExpandableMessage,
  ListingStats,
  ListingRowActions,
  PlatformListing,
  StaffDetails,
  SourceContent,
  ListingDetailCompact,
  DateTime,
  LeadInfo,
  TaskDetail,
  TableActions,
  CallRecording,
  TruBrokerScore,
};
