import tenantPayloads from '@payloads';
import tenantTransformers from '@transformers';
import tenantConstants from '@constants';
import { KC_REQUESTS } from '../../../keycloak/requests';
import { setAppUser } from '../../../store/appSlice';
import { getRequestHeaders } from '../../../store/parentApi';

const getContractsObj = (contracts) => {
  return {
    contracts: {
      olx: { active_contracts: 0, downgraded_contracts: 0, total_contracts: 0, ...contracts?.olx },
      zameen: { active_contracts: 0, downgraded_contracts: 0, total_contracts: 0, ...contracts?.zameen },
    },
  };
};
const userApiEndpoints = {
  getProfileDetailsEndpoint: (userId) => {
    return {
      url: `/api/users/${userId}`,
      transformer: (response) => tenantTransformers.profileDataMapper(response?.user),
    };
  },

  updateProfileDetailsEndpoint: (data) => {
    return {
      url: `/api/users/${data?.userId}`,
      method: 'PUT',
      body: tenantPayloads.updateProfilePayload(data?.body),
      transformer: (response) => tenantTransformers.profileDataMapper(response?.user),
    };
  },

  updateUserPreferences: ({ id, values }) => {
    return {
      url: `/api/users/${id}/preferences`,
      method: 'POST',
      body: {
        preferences: {
          email_notification: values?.email_notification,
          newsletter: values?.newsletter,
          automated_reports: values?.automated_reports,
          currency: values?.currency,
          area_unit: values?.area_unit,
        },
      },
    };
  },

  getUserActiveLicenses: {
    query: (id) => {
      return { url: `/api/users/${id}/licenses` };
    },
  },

  getUserSettingsDetail: {
    query: ({ id }) => {
      return { url: `/api/users/${id}` };
    },
    transformer: (response) => tenantTransformers.settingsDetailDataMapper(response),
  },

  changePassword: {
    query: ({ userId, values, auth }) => {
      return {
        url: tenantConstants.KC_ENABLED ? KC_REQUESTS.change_password.url : `/api/users/${userId}/change_password`,
        method: 'POST',
        body: tenantConstants.KC_ENABLED
          ? {
              old_password: values?.oldPassword,
              new_password: values?.newPassword,
            }
          : {
              user: {
                current_password: values?.oldPassword,
                new_password: values?.newPassword,
                new_password_confirmation: values?.confirmPassword,
              },
            },
        headers:
          !!auth && tenantConstants.KC_ENABLED ? getRequestHeaders(auth, {}, KC_REQUESTS.change_password.url) : {},
      };
    },
    transformer: (response) => response?.data || { message: 'Password Updated Successfully' },
  },

  fetchContractsDetails: {
    query: () => {
      return {
        url: '/api/dashboard/contracts',
      };
    },
    transformer: (response) => getContractsObj(response?.data?.contracts),
    afterSuccess: async (arg, { dispatch, queryFulfilled }) => {
      try {
        const { data } = await queryFulfilled;
        dispatch(setAppUser(data));
      } catch (error) {
        console.error('Failed to fetch contracts:', error);
      }
    },
  },
};

export default userApiEndpoints;
