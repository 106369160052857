import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAPIBaseURL, getClassifiedBaseURL } from '../utility/env';
import { KC_REQUESTS } from '../keycloak/requests';
import { getRequestHeaders } from './parentApi';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getAPIBaseURL(),
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({ url: 'auth/login', method: 'POST', body: { email: body.email, pass: body.password } }),
      transformErrorResponse: (response) => response?.data?.errors?.[0],
    }),
    logout: builder.mutation({
      query: (body) => ({ url: '/authentication/logout', method: 'POST', body }),
    }),
    logoutStrat: builder.mutation({
      query: (body) => ({ url: `${getClassifiedBaseURL()}/api/logout`, method: 'POST', body }),
    }),
    logoutKeycloak: builder.mutation({
      query: (auth) => {
        const logoutEndpoint = KC_REQUESTS.logout;
        return {
          url: logoutEndpoint.url,
          method: 'POST',
          body: logoutEndpoint.body(auth.refreshToken),
          headers: {
            ...getRequestHeaders(auth, {}, logoutEndpoint.url),
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
      },
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useLogoutStratMutation, useLogoutKeycloakMutation } = authApi;

export default authApi;
