import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { getBaseURL } from '../../../utility/env';
import { Flex, Image, Popover, TextWithIcon } from '../../common';
import { Thumbnail } from '../../styled';
import { PopoverListingContainer } from '../styled';
import { ListingPurpose } from './listing-purpose';
import { MorePropertiesPopover } from './more-properties-popover';

export const ListingDetailCompact = (props) => {
  const { interests_count, characterLimit = null, leadId } = props;
  const isMobile = useSelector((state) => state.app.AppConfig.isMobile);
  const listingTitle = `${tenantUtils.getLocalisedString(props?.type, 'title')} ${t('for')} ${tenantUtils.getLocalisedString(props?.purpose, 'title')}`;
  const listingLocation = props?.location?.breadcrumb;

  return (
    <PopoverListingContainer action={null} className={'listing-detail pointer'}>
      <Flex gap={isMobile ? '4px' : '10px'} vertical>
        <Flex gap="8px" align="center">
          <Thumbnail style={props?.thumbnailStyles}>
            <Image
              src={props?.image?.thumbnail}
              fallback={`${getBaseURL()}/profolio-assets/images/ph-listings.svg`}
              width={'36px'}
            />
          </Thumbnail>
          <div>
            {props?.type?.title && props?.purpose?.title && (
              <>
                <Popover
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  content={<ListingPurpose {...props} disableDetailDrawer hideHealthPopover />}
                  action="hover"
                  getPopupContainer={() => document.body}
                >
                  <TextWithIcon
                    justify="flex-end"
                    className="mb-4"
                    style={{ flexDirection: 'row-reverse' }}
                    icon="BsFillInfoCircleFill"
                    iconProps={{ size: '12px', color: tenantTheme.gray500 }}
                    textColor={tenantTheme.baseColor}
                    value={
                      characterLimit && characterLimit < listingTitle?.length
                        ? `${listingTitle?.slice(0, characterLimit)}...`
                        : listingTitle
                    }
                    textSize={isMobile && '12px'}
                  />
                </Popover>
              </>
            )}

            <TextWithIcon
              icon="IoLocationSharp"
              iconProps={{ size: isMobile ? '10px' : '12px', color: tenantTheme.gray550 }}
              textColor={tenantTheme.gray700}
              gap="2px"
              value={
                characterLimit && characterLimit < listingLocation?.length
                  ? `${listingLocation?.slice(0, characterLimit)}...`
                  : listingLocation
              }
              textSize={isMobile ? '10px' : '12px'}
            />
          </div>
        </Flex>
        {interests_count > 1 && (
          <MorePropertiesPopover
            title={`+ ${interests_count - 1} ${t('more properties')}.`}
            count={interests_count}
            leadId={leadId}
          />
        )}
      </Flex>
    </PopoverListingContainer>
  );
};
