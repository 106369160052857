import tenantConstants from '@constants';
import tenantMenuList from '@menuList';
import tenantTheme from '@theme';
import { Menu } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Flex, Icon } from '../components/common';
import { LangAr, LangEn } from '../components/svg';
import { useGetLocation, useRouteNavigate } from '../hooks';
import { helpCenterClickEvent } from '../services/analyticsService/index.js';
import TenantComponents from '@components';
import { getAppLanguage } from '../utility/language.js';

const langIcons = { en: LangEn, ar: LangAr };

const MenuItems = ({ t, toggleCollapsed, handleTopBarSticky, collapsed }) => {
  const navigate = useRouteNavigate();

  const pathName = window.location.pathname;
  const { user } = useSelector((state) => state.app.loginUser);
  const sideBarList = tenantMenuList.menuList(user?.permissions, user?.agency, user?.type !== 'free');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const { isMobile, darkMode, topMenu, isMemberArea, locale } = useSelector((state) => state.app.AppConfig);

  const [currentClickedItem, setCurrentClickedItem] = useState(null);
  const currentPath = useGetLocation();
  const sidebarRef = useRef(null);
  const helpSupportModalRef = useRef();

  useEffect(() => {
    const keys = [];
    sideBarList.forEach((e) => {
      if ((e.path && pathName.includes(e.path)) || pathName.includes(e?.basePath)) {
        keys.push(e.key);
        handleTopBarSticky(e.hideTopBar);
      } else if (e.list) {
        e.list.forEach((it) => {
          if ((it.path && pathName.includes(it.path)) || pathName.includes(it?.basePath)) {
            keys.push(it.key);
            handleTopBarSticky(it.hideTopBar);
          }
        });
      }
    });
    setSelectedKeys(keys);
  }, [window.location.pathname]);

  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //       toggleCollapsed();
  //     }
  //   };
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [toggleCollapsed]);

  const menuItems = useMemo(
    () =>
      sideBarList.map(({ key, path: itemPath, list, title, icon, hideTopBar, id }) =>
        list
          ? {
              id,
              key,
              icon: !!icon && <Icon icon={icon} />,
              children: list.map((subItem) => ({
                key: subItem.key,
                icon: !!subItem.icon && <Icon icon={subItem.icon} />,
                label: <div>{subItem.title}</div>,
              })),
              label: title,
            }
          : { key, icon: !!icon && <Icon icon={icon} />, label: <div>{title}</div> },
      ),
    [sideBarList],
  );

  const onChangeLanguage = (newKey) => {
    const lang = tenantConstants.LANGUAGES?.find((e) => e.key == newKey);
    const currentLanguage = getAppLanguage();
    const pathDirectories = window.location.pathname.split('/');
    let newPath;
    if (pathDirectories[1] !== lang.key) {
      if (window.location.pathname.includes(`/${currentLanguage?.key}`)) {
        newPath = [window.location.pathname.replace(`/${currentLanguage?.key}`, `/${lang?.key}`)];
      } else {
        newPath = [pathDirectories[0], lang?.key, ...pathDirectories.slice(1)];
      }
    }
    window.location.pathname = newPath.join('/');
  };

  const onClickMenuItem = (e) => {
    let item;
    sideBarList.some((it) => {
      if (e.key === it.key) {
        item = it;
        return true;
      } else if (it.list) {
        it.list.some((el) => {
          if (e.key === el.key) {
            item = el;
            return true;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    });
    if (item) {
      setCurrentClickedItem(item);
      item?.onClick && item?.onClick();
      if (item?.component) {
        sidebarRef?.current && sidebarRef?.current?.show();
        toggleCollapsed();
      } else {
        navigate(item.path);
        toggleCollapsed();
      }
    }
  };

  const languageObjectToSwitch = useMemo(
    () => tenantConstants.LANGUAGES?.length > 1 && tenantConstants.LANGUAGES?.find((e) => e.alternate === locale),
    [locale],
  );
  const LANG_ICON = langIcons[languageObjectToSwitch.key];

  const renderDynamicComponent = useCallback(() => {
    const Component = TenantComponents?.[currentClickedItem?.component];
    return (
      Component &&
      !currentPath?.pathname.includes(currentClickedItem?.path) && (
        <Component
          //  ref={sidebarRef}
          defaultState={true}
          isFetch={isMobile}
        />
      )
    );
  }, [currentClickedItem]);

  return (
    <>
      <Menu
        mode={!topMenu || isMobile ? 'inline' : 'horizontal'}
        theme={darkMode && 'dark'}
        defaultSelectedKeys={selectedKeys}
        onClick={onClickMenuItem}
        overflowedIndicator={<Icon icon="FiMoreVertical" />}
        selectedKeys={selectedKeys}
        items={menuItems}
      />
      <Flex vertical gap="8px">
        {!collapsed && <div className="text-center">{isMobile && <TenantComponents.HeaderLink />}</div>}
        {tenantConstants.SHOW_HELP_AND_SUPPORT && !isMemberArea && (
          <div className={collapsed ? 'px-8' : 'px-16'}>
            <Button
              onClick={() => {
                helpSupportModalRef.current.show(true);
                helpCenterClickEvent(user);
              }}
              outlined
              style={{
                '--btn-bg-color': tenantTheme['primary-light'],
                '--btn-content-color': tenantTheme['primary-color'],
              }}
              block
              icon="SupportIcon"
            >
              {!collapsed && t('Help & Support')}
            </Button>

            <TenantComponents.HelpAndSupport ref={helpSupportModalRef} />
          </div>
        )}
        {languageObjectToSwitch && (
          <div className={collapsed ? 'px-8' : 'px-16'}>
            <Button
              onClick={() => onChangeLanguage(languageObjectToSwitch.key)}
              outlined
              style={{
                '--btn-bg-color': tenantTheme['primary-light'],
                '--btn-content-color': tenantTheme['primary-color'],
                '--ant-button-default-hover-bg': tenantTheme['primary-light'],
              }}
              block
            >
              <>
                <LANG_ICON size="1.4em" />{' '}
                {collapsed ? languageObjectToSwitch.labelShort : languageObjectToSwitch.label}
              </>
            </Button>
          </div>
        )}
      </Flex>

      {renderDynamicComponent()}
    </>
  );
};

export default withTranslation()(MenuItems);
