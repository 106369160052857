import tenantConstants from '@constants';
import tenantUtils from '@utils';
import { Space } from 'antd';
import { t as translate } from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, DrawerModal, EmptyState, Flex, Group, Icon, JSONForm, Skeleton, Tag, Title } from '../../common';
import Label from '../../common/Label/Label';
import { IconStyled } from '../../common/icon/IconStyled';
import amenities from './amenities.json';
import { useLazyGetAmenitiesQuery } from '../../../apis/postlisting';
import TenantComponents from '@components';
const AddAmenities = (props) => {
  const {
    labelIcon,
    label = <div className="fw-700">{translate('Feature and Amenities')}</div>,
    description = translate('Add additional features e.g. parking spaces, waste disposal, internet etc.'),
    formProps,
    handleChange,
    handleBlur,
    value,
    name,
    payloadKey,
    type = 'primary',
    shape = true,
    template = 'max-content auto',
    purposeId,
    ...rest
  } = props;

  const [showModal, setShowModal] = useState(false);
  const jsonFormRef = useRef();
  const [amenitiesData, setAmenities] = useState(null);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const isMobile = useSelector((state) => state.app.AppConfig.isMobile);
  const [getAmenities, { isLoading: loading, isFetching }] = useLazyGetAmenitiesQuery();
  const data = [];
  useEffect(() => {
    value && !amenitiesData && fetchAmenities();
  }, [value]);

  const fetchAmenities = async () => {
    const response = await getAmenities(purposeId && purposeId);
    setAmenities(response?.data?.data);
  };

  const renderAddAmenitiesSkeleton = () => {
    return (
      <>
        <Flex gap="10px">
          {(isMobile ? [1, 2, 3, 4] : [1, 2, 3, 4, 5, 6]).map((e, i) => (
            <Skeleton width="100%" height={30} key={i} style={{ marginBlock: '20px' }} />
          ))}
        </Flex>

        <Group template={isMobile ? 'initial' : 'repeat(2,1fr)'} gap={isMobile && '12px'}>
          {[1, 2, 3, 4].map((e, i) => (
            <Skeleton width="100%" height={isMobile ? 40 : 60} key={i} />
          ))}
        </Group>
      </>
    );
  };

  const getIconName = (id) =>
    tenantConstants?.SHOW_AMENITIES_ICON
      ? TenantComponents?.amenities[id]?.svg
        ? TenantComponents?.amenities[id]?.svg
        : 'MdIndeterminateCheckBox'
      : '';

  const getTagsSection = useCallback(
    (arg) => {
      const arr = [];
      amenitiesData?.[payloadKey]?.fieldsSections &&
        amenitiesData[payloadKey].fieldsSections.forEach((e) => {
          const fields = {};
          e.fields.forEach((it) => {
            if (arg && !(arg[it] === '' || arg[it] === undefined || arg[it] === false)) {
              fields[it] = arg[it];
            }
          });
          !!Object.keys(fields).length && arr.push({ title: tenantUtils.getLocalisedString(e, 'title'), fields });
        });
      return arr;
    },
    [amenitiesData, payloadKey],
  );

  return (
    <Group template={template} gap="16px">
      {labelIcon && (
        <IconStyled>
          <Icon icon={labelIcon} />
        </IconStyled>
      )}

      <div className="grid" style={{ '--gap': '8px', gridTemplateColumns: '1fr auto' }}>
        <div>
          <Label htmlFor={name}>{label}</Label>
          <div className="text-muted">{description}</div>
        </div>
        <div>
          <Button
            type={type}
            shape={shape && 'round'}
            className="px-16"
            style={{ borderRadius: '6px' }}
            onClick={() => {
              setShowModal(true);
              !amenitiesData && fetchAmenities();
            }}
          >
            {t('Add Amenities')}
          </Button>
        </div>
        <Space className="span-all" size={16} direction="vertical">
          {getTagsSection(value).map((item, i) => {
            return (
              <div key={i}>
                <Title style={{ fontSize: '14px' }} className="color-gray-dark">
                  {item.title}
                </Title>
                <div className="flex" style={{ gap: '8px', flexFlow: 'wrap' }}>
                  {Object.keys(item.fields).map((e) => {
                    return (
                      <Tag
                        shape="round"
                        closable
                        onClose={() => handleChange({ ...value, [e]: undefined })}
                        key={e}
                        style={{ overflow: 'hidden', display: 'inline-flex', '--space-top': '1px' }}
                        spaceTop="1px"
                        icon={<Icon icon={getIconName(e)} style={{}} />}
                      >
                        <div style={{ overflow: 'hidden', maxWidth: '100%', textOverflow: 'ellipsis' }}>
                          {amenitiesData[payloadKey].fields[e].props.label}
                          {value[e] && typeof value[e] === 'string' && `: ${value[e]}`}
                        </div>
                      </Tag>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Space>
      </div>

      <DrawerModal
        onCancel={() => {
          jsonFormRef?.current?.resetForm();
          setShowModal(false);
        }}
        height={'100vh'}
        okText={t('Add Amenities')}
        onOk={() => {
          handleChange(jsonFormRef?.current?.getValues() || null);
          setShowModal(false);
        }}
        visible={showModal}
        title={label}
        width={900}
        styles={{ paddingBlockEnd: 6 }}
        maskClosable={false}
        {...rest}
      >
        {error ? (
          <EmptyState message={error} onClick={fetchAmenities} buttonLoading={loading} />
        ) : loading ? (
          renderAddAmenitiesSkeleton()
        ) : (
          <JSONForm
            fields={amenitiesData?.[payloadKey]?.fields || {}}
            fieldsSections={amenitiesData?.[payloadKey]?.fieldsSections || []}
            showSectionsAsTabs
            loading={loading}
            ref={jsonFormRef}
            classOfContentColumns="amenitiesModal"
            formFieldValues={value}
            noOfContentColumns={isMobile ? 1 : 2}
            groupGap="16px 24px"
            renderFieldAsCard
          />
        )}
      </DrawerModal>
    </Group>
  );
};

AddAmenities.propTypes = {
  labelIcon: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  formProps: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  value: PropTypes.object,
  name: PropTypes.string,
};

export default AddAmenities;
