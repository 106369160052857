import tenantConstants from '@constants';
import tenantData from '@data';
import { t } from 'i18next';
import React from 'react';
import store from '@store';
import { getBaseURL, getClassifiedBaseURL, TENANT_KEY } from './env';
import { numberToWords } from './numberToWords';
import { convertQueryObjToString } from './urlQuery';
import { getLocaleForURL } from './language';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const stSlash = (path) => {
  if (path) {
    if (path.endsWith('/')) {
      return stSlash(path.slice(0, path.length - 1));
    }
    if (path.endsWith('*')) {
      return stSlash(path.slice(0, path.length - 2));
    }
    return path;
  }
  return '';
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getMaxForChart(data) {
  let max = 0;
  for (const key in data) {
    const maxValueFromArray =
      data[key].length > 0
        ? data[key].reduce((a, b) => {
            return Math.max(a, b);
          })
        : 0;
    if (maxValueFromArray > max) {
      max = maxValueFromArray;
    }
  }

  if (max >= 0 && max <= 5) {
    return !max ? 1 : max;
  }
  if (max > 5 && max <= 10) {
    return Math.ceil(max / 2) * 2;
  }
  if (max > 10 && max <= 100) {
    return Math.ceil(max / 10) * 10;
  }
  if (max > 100 && max <= 1000) {
    return Math.ceil(max / 100) * 100;
  }
  if (max > 1000 && max <= 10000) {
    return Math.ceil(max / 1000) * 1000;
  }
  return max;
}

function getStepForChart(data) {
  let max = getMaxForChart(data);
  if (max >= 0 && max <= 5) {
    return 1;
  }
  if (max > 5 && max <= 10) {
    return 2;
  }
  if (max > 10 && max <= 50) {
    return 10;
  }
  if (max > 50 && max <= 100) {
    return 20;
  }
  if (max > 100 && max <= 500) {
    return 100;
  }
  if (max > 500 && max <= 1000) {
    return 200;
  }
  if (max > 1000 && max <= 2000) {
    return 500;
  }
  if (max > 2000 && max <= 5000) {
    return 1000;
  }
  if (max > 5000 && max <= 10000) {
    return 2000;
  }
  return parseInt(max / 7) - (parseInt(max / 7) % 10);
}

const getResultGroup = (noOfDays, isLegion = false) => {
  if (isLegion) {
    if (noOfDays > 364) {
      return { result_group: 'year' };
    } else if (noOfDays > 89) {
      return { result_group: 'month' };
    } else return { result_group: 'day' };
  } else {
    if (noOfDays > 364) {
      return { result_group: 'yy' };
    } else if (noOfDays > 90) {
      return { result_group: 'mm' };
    } else if (noOfDays > 14) {
      return { result_group: 'ww' };
    } else return { result_group: 'dd' };
  }
};

const formatPrice = (value) => {
  if (value) {
    const [integerPart] = value?.toString()?.split('.');
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

const isNoGraph = (obj) => {
  if (Array.isArray(obj)) {
    return obj.every((value) => value === 0);
  }
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (!isNoGraph(obj[key])) {
        return false;
      }
    }
  }
  return true;
};
export const numberFormat = (value, format, options) => {
  return new Intl.NumberFormat(format, options).format(value);
};

export const formatCompactedNumber = (num, dashForNone, intl) => {
  if (intl) {
    const { locale } = store.getState().app.AppConfig;
    return locale == 'en' ? capitalizeFirstLetter(numberToWords(num, false, locale, true)) : null;
    // return new Intl.NumberFormat().format(num);
  }

  if (tenantConstants.NUMBER_SYSTEM == 'western') {
    switch (true) {
      case null:
      case undefined:
      case '' && dashForNone:
        return '-';
      case num >= 0 && num < 1000:
        return num.toFixed(num % 1 ? 2 : 0);
      case num >= 1000 && num < 1000000:
        return (num / 1000).toFixed((num / 1000) % 1 ? 2 : 0) + t('Thousand');
      case num >= 1000000 && num < 1000000000:
        return (num / 1000000).toFixed((num / 1000000) % 1 ? 2 : 0) + t('Million');
      case num >= 1000000000 && num < 1000000000000:
        return (num / 1000000000).toFixed((num / 1000000000) % 1 ? 2 : 0) + t('Billion');
      case num >= 1000000000000 && num < 1000000000000000:
        return (num / 1000000000000).toFixed((num / 1000000000000) % 1 ? 2 : 0) + t('Trillion');
      case num >= 1000000000000000:
        return (num / 1000000000000000).toFixed((num / 1000000000000000) % 1 ? 2 : 0) + t('Quadrillion');
      default:
        return num;
    }
  } else {
    switch (true) {
      case null:
      case undefined:
      case '' && dashForNone:
        return '-';
      case num >= 0 && num < 1000:
        return num.toFixed(num % 1 ? 2 : 0);
      case num >= 1000 && num < 100000:
        return (num / 1000).toFixed((num / 1000) % 1 ? 2 : 0) + t('Thousand');
      case num >= 100000 && num < 10000000:
        return (num / 100000).toFixed((num / 100000) % 1 ? 2 : 0) + t('Lakh');
      case num >= 10000000 && num < 1000000000:
        return (num / 10000000).toFixed((num / 10000000) % 1 ? 2 : 0) + t('Crore');
      case num >= 1000000000:
        return (num / 1000000000).toFixed((num / 1000000000) % 1 ? 2 : 0) + t('Arab');
    }
  }
};

const formatNumberString = (num, options) => {
  const { dashForNone, fractionDigits, ...rest } = options || {};
  if (!num) {
    if (dashForNone) {
      return '-';
    }
    return 0;
  }

  if (options?.notation == 'compact') return formatCompactedNumber(Number(num), dashForNone);
  const fracDigits = !!fractionDigits ? (Number(num) % 1 ? fractionDigits : 0) : Number(num) % 1 ? 2 : 0;
  return new Intl.NumberFormat('en-PK', {
    ...(fracDigits <= 2 && { minimumFractionDigits: 0 }),
    maximumFractionDigits: fracDigits,
    ...rest,
  }).format(num);
};

const LocaliseCurrencyFromPrice = (inputString) => {
  const { locale } = store.getState().app.AppConfig;
  const regex = /^([A-Za-z]{3})\s*([\d,]+)$/;
  //TO DO Make single regex
  const regexwithDash = /^-([A-Za-z]{3})\s*([\d,]+)$/;
  const match = inputString.match(regex) ? inputString.match(regex) : inputString.match(regexwithDash);

  if (match) {
    const currency = match[1].toUpperCase();
    const value = match[2];
    const currencySymbol = tenantConstants.CURRENCY_SYMBOL() || currency;

    if (match[0].includes('-')) {
      return locale === 'en' ? (
        <>
          -{currencySymbol} {value}
        </>
      ) : (
        <>
          {currencySymbol} {value}-
        </>
      );
    } else {
      return (
        <>
          {currencySymbol} {value}
        </>
      );
    }
  } else {
    return inputString;
  }
};

const formatPriceValue = (
  priceValue,
  currencyOptions,
  showCurrency = true,
  fractionDigits,
  localiseCurrency = true,
) => {
  if (!isNaN(Number(priceValue))) {
    if (currencyOptions?.notation == 'compact')
      return showCurrency
        ? tenantConstants.CURRENCY_SYMBOL(
            ' ' + formatCompactedNumber(Number(priceValue), currencyOptions?.dashForNone, currencyOptions?.intl),
          )
        : formatCompactedNumber(Number(priceValue), currencyOptions?.dashForNone, currencyOptions?.intl);
    const fracDigits = !!fractionDigits
      ? Number(priceValue) % 1
        ? fractionDigits
        : 0
      : Number(priceValue) % 1
        ? 2
        : 0;
    let formattedPrice = new Intl.NumberFormat('en-PK', {
      currency: tenantConstants.CURRENCY,
      style: 'currency',
      currencyDisplay: 'symbol',
      ...(fracDigits <= 2 && { minimumFractionDigits: 0 }),
      maximumFractionDigits: fracDigits,
      ...currencyOptions,
    }).format(priceValue);

    return localiseCurrency ? LocaliseCurrencyFromPrice(formattedPrice) : formattedPrice;
  }
  return '-';
};

export const generateUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// Array Objects

const assignDefaultValueToObject = (obj, value) => {
  let newObj = new Proxy(obj, { get: (target, name) => (target.hasOwnProperty(name) ? target[name] : value) });
  return newObj;
};

const getUniqueArrayByObjKey = (array, key) => {
  return [
    ...new Map(
      array.map((item) => [Array.isArray(key) ? key.map((e) => item[e]).join('-') : item[key], item]),
    ).values(),
  ];
};

const getOBJValueByKey = (object, path, defval = null) => {
  if (!path) return defval;
  if (typeof path === 'string') path = path.split('.');
  return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : defval), object);
};

const getPlatformsAndProducts = (platforms) => {
  if (!platforms) return { products: { platforms: {} }, platforms: [] };
  const quotaCreditProducts = store.getState()?.app?.products?.data?.length
    ? store.getState().app?.products?.data
    : tenantData.products;
  const userProducts = {};
  const userPlatforms = [];

  tenantData.platformList.forEach(({ responseKey, key, ...rest }) => {
    const platform = platforms[responseKey];
    if (platform?.mapped) {
      userProducts[key] = quotaCreditProducts?.filter((e) => e?.platform === key);
      userPlatforms.push({ key, ...rest });
    }
  });

  return {
    products: { platforms: userProducts },
    platforms: userPlatforms,
  };
};

const getKeyValueFromObject = (obj, path, assignValue, valueExists) => {
  const a = { ...obj };
  let value = a;
  const keys = path
    .replace(/\[(\w+)\]/g, '.$1')
    .replace(/^\./, '')
    .split('.');

  keys.forEach((item, index) => {
    if (item in value) {
      if (index == keys.length - 1 && assignValue) {
        if (valueExists) {
          value[item] = { ...value[item], ...assignValue };
        } else {
          value[item] = assignValue;
        }
      } else {
        value = value[item];
      }
    } else {
      return;
    }
  });
  return assignValue ? a : value;
};


const getErrorString = (error) => {
  const errors =
    error?.response?.data?.errors || error?.response?.data?.error_description || error?.response?.data?.error;
  if (errors) {
    if (typeof errors === 'string') return errors;
    if (typeof errors === 'object')
      return Object.keys(errors)
        .map((key) => errors[key])
        .join(', ');
  }
  if (error && error.response?.data?.message) return error.response?.data?.message;
  if (error && error.message) return error.message;
  if (error && typeof error === 'string') return error;
};

const getErrorAllResponse = (response) => {
  let errorArray = [];
  response.forEach((e) => e?.error && errorArray.push(e.error));
  return errorArray.join(', ');
};

const formatMobile = (data, type) => {
  const replaceNumber = (number) => {
    let mobileNumberList;
    if (TENANT_KEY === 'zameen') {
      if (number.startsWith('03')) {
        mobileNumberList = number.replace('03', '+923');
      } else if (number.startsWith('+923')) {
        mobileNumberList = number;
      } else if (number?.startsWith('042')) {
        mobileNumberList = number?.replace('042', '+9242');
      } else if (number?.startsWith('92')) {
        mobileNumberList = number?.replace('92', '+92');
      } else if (number?.startsWith('--92')) {
        mobileNumberList = number?.replace('--92', '+92');
      } else if (number?.startsWith('--03')) {
        mobileNumberList = number?.replace('--03', '+923');
      } else if (number?.startsWith('+92-')) {
        mobileNumberList = number?.replace('+92-', '+92');
      } else if (number?.startsWith('-92')) {
        mobileNumberList = number?.replace('-92', '+92');
      } else if (number?.startsWith('3')) {
        mobileNumberList = number?.replace('3', '+923');
      } else if (number?.startsWith('-3')) {
        mobileNumberList = number?.replace('-3', '+923');
      } else if (number?.startsWith('--3')) {
        mobileNumberList = number?.replace('--3', '+923');
      } else if (number?.startsWith('+92')) {
        mobileNumberList = number;
      } else if (number === '-' || number === '--') {
        mobileNumberList = '';
      } else {
        mobileNumberList = `${number}`;
      }
    } else if (TENANT_KEY === 'bayut') {
      if (number === null) {
        number = '';
      }
      if (number?.startsWith('0')) {
        number = '';
      }
      if (number?.includes('-')) {
        number = number?.replace(/-/g, '');
      }
      if (number?.startsWith('966')) {
        number = number?.replace('966', '+966');
      }
      const cleanedNumber = number.replace(/[^\d+]/g, '');
      if (tenantConstants.PHONE_REGEX.test(cleanedNumber)) {
        mobileNumberList = cleanedNumber;
      } else if (cleanedNumber === '-' || cleanedNumber === '--') {
        mobileNumberList = '';
      } else {
        mobileNumberList = `${number}`;
      }
    }

    return mobileNumberList;
  };
  try {
    if (type === 'singleNumber') {
      return replaceNumber(
        typeof data === 'string'
          ? data.replaceAll('-', '')
          : typeof data === 'object' && data[0]
            ? data[0].replaceAll('-', '')
            : '',
      );
    } else {
      if (typeof data === 'string') {
        return [replaceNumber(data.replaceAll('-', ''))];
      } else if (typeof data === 'object') {
        return data.map((item) => replaceNumber(item.replaceAll('-', '')));
      } else {
        return '';
      }
    }
  } catch (error) {
    return typeof data === 'string' ? '' : [''];
  }
};

const allowInput = (type, value, limit) => {
  if (!value) return true;
  const regex = {
    number: new RegExp('^(\\d|[1-9]\\d' + `{1,${limit ? limit : 12}})` + '(?:[.]\\d{0,2})?$'),
    text: new RegExp(`^(.|[\f\n\r\t\v]){1,${limit ? limit : 250}}$`),
  };
  return regex[type].test(value);
};

const isWebView = () => {
  const userAgent = navigator.userAgent || '';
  const isIOSWebView = /AppleWebKit/.test(userAgent) && !/Safari/.test(userAgent);
  const isAndroidWebView = /com\.bayut\.bayutsaapp/.test(userAgent);
  return isIOSWebView || isAndroidWebView;
};

const openExternal = (value, type = 'phone') => {
  switch (type) {
    case 'email':
      window.open(`mailto:${value}`, '_blank');
      break;
    case 'whatsapp':
      window.open(`https://wa.me/${value}`, '_blank');
      break;
    default:
      window.open(`tel:${value}`, '_blank');
  }
};
const joinBytItems = (array, separator) => {
  const filtered = array.filter((e) => !!e);
  return filtered.map((e, i) => {
    if (typeof separator === 'string') {
      return i + 1 < filtered.length ? e + separator : e;
    } else {
      return i + 1 < filtered.length ? (
        <React.Fragment key={i}>
          {e}
          {separator}
        </React.Fragment>
      ) : (
        e
      );
    }
  });
};

const mapQueryParams = (params, filtersList) => {
  if (!params) return '';
  if (params && !filtersList?.length) return convertQueryObjToString(params);
  const decodedParams = Object.entries(params).reduce((acc, [key, value]) => {
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});

  let queryParams = [];
  let listItems = [];
  const processedKeys = [];
  let unitKey = null;

  filtersList.forEach((item) => {
    if (item?.subList?.length) {
      item.subList.forEach((subListItem) => {
        const paramValue = decodedParams[subListItem.key];
        if (paramValue) {
          listItems.push(subListItem);
          processedKeys.push(subListItem.key);
        }
      });
    } else {
      const paramValue = decodedParams[item.key];
      if (paramValue) {
        listItems.push(item);
        processedKeys.push(item.key);
      }
    }
  });

  listItems.forEach((item) => {
    if (item?.queryParamKey) {
      const paramValue = decodedParams[item?.key];
      const values = paramValue.split(',');
      if (typeof item.queryParamKey === 'string') {
        values.forEach((value) => {
          queryParams.push(`${item.queryParamKey}=${value}`);
        });
      } else if (Array.isArray(item.queryParamKey)) {
        item.queryParamKey.forEach((key, index) => {
          queryParams.push(`${key}=${values[index] || ''}`);
          if (item.queryParamKey?.[2]) unitKey = item.queryParamKey?.[2];
        });
      } else if (typeof item.queryParamKey === 'function') {
        values.forEach((value) => {
          queryParams.push(item.queryParamKey(value));
        });
      }
    }
  });

  Object.keys(decodedParams).forEach((paramKey) => {
    if (!processedKeys.includes(paramKey)) {
      // O(n) lookup
      const paramValue = decodedParams[paramKey];
      queryParams.push(`${paramKey}=${paramValue}`);
    }
  });
  if (unitKey) queryParams = queryParams.filter((param) => !param.startsWith(unitKey));

  return queryParams.join('&');
};

function convertArrayToQueryString(arr, paramName) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return '';
  }

  const queryString = arr.map((item) => `${paramName}[]=${item}`).join('&');
  return queryString;
}

const getCurrentLocationPath = (currentPath) => {
  return currentPath?.pathname?.split('/').pop();
};

const getLoginPath = () => {
  const redirectedUrl = window.location.href;
  const localePath = getLocaleForURL();

  const path = tenantConstants.HAVE_LOGIN_SCREEN
    ? `${window.location.origin}${localePath}/signin`
    : `${getClassifiedBaseURL()}${localePath}/${TENANT_KEY === 'zameen' ? 'login.html?r=' : 'account?externalRedirectPath='}${
        redirectedUrl ? redirectedUrl : `${getBaseURL()}/${localePath}`
      }`;
  return path;
};
const getPositionSuffix = (number) => {
  const suffixArray = ['th', 'st', 'nd', 'rd'];
  if (number == 1 || number == 2 || number == 3) {
    return suffixArray[number];
  }
  return suffixArray[0];
};

export {
  allowInput,
  assignDefaultValueToObject,
  capitalizeFirstLetter,
  ellipsis,
  formatMobile,
  formatNumberString,
  formatPrice,
  formatPriceValue,
  getErrorAllResponse,
  getErrorString,
  getMaxForChart,
  getOBJValueByKey,
  getResultGroup,
  getStepForChart,
  getPlatformsAndProducts,
  getUniqueArrayByObjKey,
  stSlash,
  isWebView,
  openExternal,
  joinBytItems,
  mapQueryParams,
  convertArrayToQueryString,
  isNoGraph,
  getCurrentLocationPath,
  getLoginPath,
  getPositionSuffix,
};
