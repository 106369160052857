import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import parentApi from './parentApi';
import authApi from './authApi';
import appReducer from './appSlice';
import authReducer from './authSlice';

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    return undefined;
  }
}

const reducers = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [parentApi.reducerPath]: parentApi.reducer,
  [appReducer.name]: appReducer.reducer,
  [authReducer.name]: authReducer.reducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(authApi.middleware, parentApi.middleware, thunk),
  devTools: process.env.REACT_APP_ENVIRONMENT === 'development',
});

store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage(state);
});

export default store;
