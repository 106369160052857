import tenantConstants from '@constants';
import tenantRoutes from '@routes';
import { getBaseURL, getClassifiedBaseURL, isDevelopment } from '../utility/env';
import { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getLoginPath } from '../utility/utility';
import { mapUserFromIdToken } from '../utility/user';
import { useSelector } from 'react-redux';
import { getLocaleForURL } from '../utility/language';

export const useAppRedirection = () => {
  const [show, setShow] = useState(false);
  const auth = useSelector((state) => state.auth.login);

  // Redirection for login and app paths
  useEffect(() => {
    const auth_token = Cookies.get(tenantConstants.AUTH_TOKEN_COOKIE_KEY);
    if (tenantConstants.KC_ENABLED) {
      auth.initialized && redirectIfPathExists();
    } else if (auth_token || tenantConstants.HAVE_LOGIN_SCREEN) {
      redirectIfPathExists();
    } else if (!isDevelopment) {
      window.location.href = getLoginPath();
    }
  }, [auth.idToken]);

  // Redirect to first then render app
  const redirectIfPathExists = useCallback(() => {
    let pathToChangeOrigin;
    const pathToRedirect = tenantRoutes.redirectToAppPath();
    const mappedUser = auth?.idToken && mapUserFromIdToken(auth.idToken);
    if (tenantConstants.KC_ENABLED && mappedUser) {
      pathToChangeOrigin = getPathToRedirect(mappedUser, !mappedUser.is_package_user);
    }
    if (pathToRedirect || pathToChangeOrigin) {
      // Path that will be generated if any relevant path or origin change (for lite experience) is there
      const finalPath =
        (pathToChangeOrigin?.origin || getBaseURL()) +
        (pathToRedirect ? getLocaleForURL() + pathToRedirect : pathToChangeOrigin.path);
      console.log('Mapped User ===> ', mappedUser, pathToChangeOrigin, finalPath);
      window.location.replace(finalPath);
    } else {
      setShow(true);
    }
  }, [auth.idToken]);

  const getPath = (pathname, searchString, availablePaths) => {
    const isAllowedPath = availablePaths?.findIndex((route) => pathname?.includes(route?.path));
    return isAllowedPath == -1 ? availablePaths?.[0]?.path : pathname + searchString;
  };

  const getPathToRedirect = (user, isMemberArea) => {
    if (isMemberArea && window.location.origin != getClassifiedBaseURL()) {
      // lite experience
      const appPaths = tenantRoutes.app('', true, user);
      return {
        origin: getClassifiedBaseURL(),
        path:
          window.location.pathname.length > 1
            ? getPath(window.location.pathname, window.location.search, appPaths)
            : appPaths?.[0]?.path,
      };
    } else if (!isMemberArea && window.location.origin != getBaseURL()) {
      // pro experience
      return { origin: getBaseURL(), path: window.location.pathname + window.location.search };
    }
    return;
  };

  const setUserStorage = (user) => {
    const { id, is_package_user, is_suspended } = user;
    const localUserInfo = localStorage.getItem(tenantConstants.USER_LOCAL_STORAGE_KEY);
    (!localUserInfo || localUserInfo?.id != id) &&
      localStorage.setItem(
        tenantConstants.USER_LOCAL_STORAGE_KEY,
        JSON.stringify({ id, is_package_user, is_suspended }),
      );
  };

  const onLanding = (user, isMemberArea) => {
    return new Promise((resolve) => {
      if (tenantConstants.HAS_MEMBER_AREA) {
        if (user) {
          setUserStorage(user);
          const redirect = getPathToRedirect(user, isMemberArea);
          redirect && window.location.replace(redirect.origin + redirect.path);
          setTimeout(() => {
            resolve();
          }, 10);
        }
      } else {
        resolve();
      }
    });
  };

  return { onLanding, show };
};
