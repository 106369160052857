import tenantConstants from '@constants';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BreakdownByAreaWidget from './BreakdownByAreaWidget';
import ListingBreakdown from './listing-breakdown/listing-breakdown';
import { ContainerWidgets } from './styled';

function ReportsListingsSection({ rootClassName, user, data, fetchWidgetData, section }) {
  const loggedInUser = useSelector((state) => state.app.loginUser.user);
  const isMobile = useSelector((state) => state.app.AppConfig.isMobile);
  const platforms = useMemo(
    () => (user ? user?.platforms : loggedInUser?.platforms),
    [user?.platforms?.length, loggedInUser?.platforms?.length],
  );
  const isMultiPlatform = useMemo(() => platforms?.length > 1, [platforms?.length]);
  const selectedPlatform = useSelector((state) => state.app.sectionPlatform?.[section]);

  const renderComponents = (platform) => {
    return (
      <ContainerWidgets
        className={rootClassName}
        template={isMobile ? '1fr' : '32% minmax(0, 1fr)'}
        gap="16px"
        key={platform?.key}
      >
        <div>
          <ListingBreakdown
            brandColor={platform?.brandColor}
            linear
            trends={false}
            minHeight="calc(100% - 42px)"
            user={user ? user : loggedInUser}
            platform={platform?.slug}
            icon={isMultiPlatform && tenantConstants.MULTIPLATFROM_VIEW ? platform?.icon : null}
            showLink={false}
          />
        </div>

        <div>
          <BreakdownByAreaWidget
            icon={isMultiPlatform && tenantConstants.MULTIPLATFROM_VIEW && platform?.icon}
            user={user ? user : loggedInUser}
            platform={platform?.slug}
          />
        </div>
      </ContainerWidgets>
    );
  };
  return (
    <>
      {isMultiPlatform && tenantConstants.MULTIPLATFROM_VIEW
        ? platforms?.map((platform) => renderComponents(platform))
        : renderComponents(selectedPlatform)}
    </>
  );
}

export default ReportsListingsSection;
