import rtkApis from '@rtkApis';
import tenantTransformers from '@transformers';
import { setAppUser, setMemberArea, setMultiPlatform } from '../store/appSlice';
import parentApi from '../store/parentApi';

const userApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getUserDetailFromToken: build.query({
      query: () => ({ url: `/api/users/current`, method: 'GET' }),
      providesTags: ['current'],
      transformResponse: (response) => tenantTransformers.userDetailTransformer(response),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          const isMemberArea = !response?.data?.is_package_user;
          dispatch(setAppUser(response?.data));
          dispatch(setMemberArea(isMemberArea));
          dispatch(setMultiPlatform(!!response?.data?.is_multi_platform));
        } catch (e) {
          console.error('Failed to update Login User', e);
        }
      },
    }),

    getProfileDetails: build.query({
      query: (userId) => {
        return rtkApis.getProfileDetailsEndpoint(userId)?.url;
      },
      transformResponse: (response) => {
        return rtkApis.getProfileDetailsEndpoint()?.transformer(response);
      },
      providesTags: ['user-profile'],
    }),

    updateProfileDetails: build.mutation({
      query: (data) => rtkApis.updateProfileDetailsEndpoint(data),
      transformResponse: (response) => {
        return rtkApis.updateProfileDetailsEndpoint()?.transformer(response);
      },
      invalidatesTags: (result, error) => {
        return result ? ['user-profile', 'profile', 'current'] : [];
      },
    }),

    getFavouriteProperties: build.query({
      query: () => rtkApis.getFavouriteProperties.query(),
      providesTags: ['fav-proprties'],
    }),

    saveSettings: build.mutation({
      query: (params) => rtkApis.saveSettings.query(params),
    }),

    getUserActiveLicenses: build.query({
      query: (params) => rtkApis.getUserActiveLicenses.query(params),
      providesTags: ['licenses'],
    }),

    updateLicense: build.mutation({
      query: (params) => rtkApis.updateLicense.query(params),
      invalidatesTags: (result, error) => {
        return result ? ['licenses'] : [];
      },
    }),

    updateUserPreferences: build.mutation({
      query: (userId) => rtkApis.updateUserPreferences(userId),
      invalidatesTags: ['current', 'profile'],
    }),

    changePassword: build.mutation({
      query: (query) => rtkApis.changePassword?.query(query),
      transformResponse: (response) => rtkApis.changePassword?.transformer(response),
    }),

    getUserSettingsDetail: build.query({
      query: (id) => rtkApis.getUserSettingsDetail?.query(id),
      transformResponse: (response) => rtkApis.getUserSettingsDetail?.transformer(response),
      providesTags: ['profile'],
    }),

    getUsersSavedSearches: build.query({
      query: (queryParams) => rtkApis.getUsersSavedSearches.query(queryParams),
      providesTags: ['saved-searches'],
    }),

    deleteSavedSearch: build.mutation({
      query: (userId) => rtkApis.deleteSavedSearch(userId),
      invalidatesTags: (result, error) => {
        return result ? ['saved-searches'] : [];
      },
    }),

    getUserOtp: build.query({
      query: (queryParams) => rtkApis.getUserOtp.query(queryParams),
    }),

    getUserOtpViaKC: build.mutation({
      query: (queryParams) => rtkApis.getUserOtpViaKC.query(queryParams),
    }),

    verifyUserOtp: build.mutation({
      query: (queryParams) => rtkApis.verifyUserOtp.query(queryParams),
    }),

    fetchContractsDetails: build.query({
      query: (queryParams) => rtkApis.fetchContractsDetails?.query(queryParams),
      async onQueryStarted(arg, api) {
        rtkApis.fetchContractsDetails?.afterSuccess(arg, api);
      },
    }),

    getTruPointsCriteriaData: build.query({
      query: () => rtkApis.getTruPointsCriteriaData.query()?.url,
      transformResponse: (response) => {
        return response;
      },
    }),

    getTruPointsActivityLogsData: build.query({
      query: (query) => rtkApis.getTruPointsActivityLogsData.query(query)?.url,
      transformResponse: (response) => {
        return response;
      },
    }),

    getTruBrokerLeaderboardData: build.query({
      query: () => rtkApis.getTruBrokerLeaderboardData.query()?.url,
      transformResponse: (response, meta, args) =>
        rtkApis.getTruBrokerLeaderboardData.transformer(response, meta, args),
    }),

    verifyOtpViaKC: build.mutation({
      query: (queryParams) => rtkApis.verifyOtpViaKC.query(queryParams),
    }),
  }),
});

export const {
  useGetUserDetailFromTokenQuery,
  useLazyGetFavouritePropertiesQuery,
  useSaveSettingsMutation,
  useLazyGetUserDetailFromTokenQuery,
  useGetUserActiveLicensesQuery,
  useVerifyOtpViaKCMutation,
  useFetchContractsDetailsQuery,
  useGetUserOtpViaKCMutation,
  useLazyGetUsersSavedSearchesQuery,
  useDeleteSavedSearchMutation,
  useGetProfileDetailsQuery,
  useLazyGetUserSettingsDetailQuery,
  useGetUserSettingsDetailQuery,
  useChangePasswordMutation,
  useUpdateProfileDetailsMutation,
  useUpdateUserPreferencesMutation,
  useUpdateLicenseMutation,
  useVerifyUserOtpMutation,
  useLazyGetUserOtpQuery,
  useLazyGetTruPointsCriteriaDataQuery,
  useLazyGetTruPointsActivityLogsDataQuery,
  useLazyGetTruBrokerLeaderboardDataQuery,
} = userApis;
