import tenantConstants from '@constants';
import { BroadcastChannel } from 'broadcast-channel';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import keycloak from '../keycloak';
import { removeCookies } from '../utility/cookies';
import { broadCastChannelName, cookieDomain, isDevelopment } from '../utility/env';
import { removeAppTokens, setAppTokens } from '../store/authSlice';
import { useLoginMutation, useLogoutStratMutation, useLogoutKeycloakMutation } from '../store/authApi';
import { getLoginPath } from '../utility/utility';
import useRouteType from './useRouteType';

const channel = tenantConstants.KC_ENABLED && broadCastChannelName && new BroadcastChannel(broadCastChannelName);

const useAppAuthentication = (initialize) => {
  const REFRESH_TIME_GAP = 15000; // Time to refresh before expiry (00:00:15)

  const auth = useSelector((state) => state.auth.login);
  const dispatch = useDispatch();
  const authRef = useRef(auth);
  const routeType = useRouteType();

  const [login] = useLoginMutation();
  const [logoutStrat] = useLogoutStratMutation();
  const [logoutKeycloak] = useLogoutKeycloakMutation();

  const awaitForUserToken = auth.authenticated || !auth.initialized;

  const canInitializeKC = () => initialize && tenantConstants.KC_ENABLED && routeType !== 'public';
  const getMessage = (k) => ({ idToken: k.idToken, refreshToken: k.refreshToken, token: k.accessToken });

  useEffect(() => {
    if (canInitializeKC()) {
      authRef.current = auth;
    }
  }, [auth.token]);

  useEffect(() => {
    if (canInitializeKC()) {
      initializeKeycloak();
      document.addEventListener('visibilitychange', refreshTokenAfterTabChange);
      channel.onmessage = (message) => onMessage(message);
    }
  }, []);

  useEffect(() => {
    // To Check
    if (canInitializeKC() && auth.token) {
      try {
        const decodedToken = jwtDecode(auth.token);
        const expiryTime = decodedToken.exp * 1000;
        const now = Date.now();
        const delay = expiryTime - now - REFRESH_TIME_GAP;
        if (delay > 0) {
          const timer = setInterval(() => {
            if (document.visibilityState === 'visible') {
              handleRefreshToken(auth.refreshToken);
            }
          }, delay);
          return () => {
            clearTimeout(timer);
          };
        }
      } catch (e) {
        console.log('Error in hitting refresh token: ', e);
      }
    }
  }, [auth.authenticated]);

  const setStoreTokens = (obj, rest) => {
    const authObj = {
      token: obj.token,
      idToken: obj.idToken,
      refreshToken: obj.refreshToken,
      initialized: true,
      authenticated: true,
      ...rest,
    };
    dispatch(setAppTokens(authObj));
  };

  const initializeKeycloak = async () => {
    if (isDevelopment) {
      try {
        const kc = await keycloak;
        const authenticated = await kc.init({ onLoad: 'login-required' });
        setStoreTokens(kc, { authenticated });
        if (!authenticated) {
          kc.login();
        }
      } catch (error) {
        console.error('=> KC Init error: ', error);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      keycloak
        .init()
        .then((authenticated) => {
          if (!authenticated) {
            keycloak.login();
            return;
          } else {
            setStoreTokens(keycloak, { token: keycloak.accessToken });
          }
        })
        .catch((error) => {
          onLogout();
        });
    }
  };

  const handleRefreshToken = async (rT, onSuccess = () => {}) => {
    if (tenantConstants.KC_ENABLED) {
      if (isDevelopment) {
        const kc = await keycloak;
        kc.updateToken();
      } else {
        keycloak
          .refresh(rT || auth.refreshToken)
          .then(() => {
            setStoreTokens(keycloak, { token: keycloak.accessToken });
            channel.postMessage(JSON.stringify(getMessage(keycloak)));
            onSuccess();
          })
          .catch((e) => {
            console.log('On Refresh Error: ', e);
            onLogout();
          });
      }
    } else {
      return;
    }
  };

  const refreshTokenAfterTabChange = () => {
    if (!document.hidden) {
      try {
        const currentAuth = authRef.current;
        const decodedToken = jwtDecode(currentAuth?.token);
        const expiryTime = decodedToken.exp * 1000;
        const now = Date.now();
        const delay = expiryTime - now - REFRESH_TIME_GAP;
        if (delay <= 0) {
          handleRefreshToken(currentAuth.refreshToken);
        }
      } catch (e) {
        console.log('Error on tab change refresh: ', e);
      }
    }
  };

  const onMessage = (message) => {
    const data = JSON.parse(message);
    setStoreTokens(data);
  };

  const redirectToLogin = () => {
    dispatch(removeAppTokens());
    window.location.replace(getLoginPath());
  };

  const onLogin = (values) => {
    if (!tenantConstants.KC_ENABLED) {
      login(values).then((response) => {
        dispatch(
          setAppTokens({ token: response?.data?.token, authenticated: !!response?.data?.token, initialized: true }),
        );
        response?.data?.user &&
          Cookies.set(
            tenantConstants.AUTH_TOKEN_COOKIE_KEY,
            response?.data?.token,
            cookieDomain && { domain: cookieDomain },
          );
      });
    }
  };

  const onLogout = useCallback(async () => {
    if (tenantConstants.KC_ENABLED) {
      if (isDevelopment) {
        const kc = await keycloak;
        kc.logout();
      } else {
        keycloak.logout();
        auth.refreshToken && (await logoutKeycloak(auth));
      }
      redirectToLogin();
    } else {
      !isDevelopment && (await logoutStrat());
      dispatch(removeAppTokens());
      removeCookies();
    }
  }, [auth.token]);

  return { auth, awaitForUserToken, redirectToLogin, onLogin, onLogout, handleRefreshToken };
};
export default useAppAuthentication;
