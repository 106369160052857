import tenantRoutes from '@routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Icon, LinkWithIcon, Popover, TextInput } from '../../../../components/common';
import { IconSwitch } from '../../../../components/svg';
import { useRouteNavigate } from '../../../../hooks';
import { getCurrentLocationPath, stSlash } from '../../../../utility/utility';
import { AtbdTopDropdwon } from './style';
import { getClassifiedBaseURL } from '../../../../utility/env';
import { addPropertyEvent } from '../../../../services/analyticsService';

const HeaderComponent = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.app.loginUser);
  const navigate = useRouteNavigate();
  const [value, setValue] = useState('');
  const isMobile = useSelector((state) => state.app.AppConfig.isMobile);
  const location = useLocation();

  const getHeaderComponents = (path) => [
    {
      path: `${path}/listings`,
      title: t('My Listings'),
      icon: 'SideMenuProperty',
      iconColor: '#00a651',
      type: 'default',
      outlined: false,
    },
    {
      path: `${path}/post-listing`,
      title: t('Post Listing'),
      icon: 'MdOutlineAddLocationAlt',
      type: 'primary',
      onClick: () => {
        addPropertyEvent(user, getCurrentLocationPath(location), false);
      },
    },
  ];

  const onSearch = () => {
    navigate(`${tenantRoutes.app().listings.path}?q[listing_id_eq]=${value}`);
  };

  const content = (
    <AtbdTopDropdwon
      className="search-widget grid align-items-center"
      style={{ '--template': 'auto auto', gap: '10px' }}
    >
      <TextInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('Search By ID')}
        prefixIcon="BiSearch"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSearch(e.target.value);
          }
        }}
        autoFocus
      />
      <Button type="primary" size="large" onClick={onSearch}>
        {t('Search')}
      </Button>
    </AtbdTopDropdwon>
  );

  return (
    <>
      {!isMobile && (
        <>
          <LinkWithIcon
            as="a"
            icon={<IconSwitch />}
            linkTitle={t('Go to zameen.com')}
            href={getClassifiedBaseURL()}
            className="btnLink px-8"
            target="_blank"
          />

          {getHeaderComponents(stSlash('')).map((e, index) => (
            <Button
              type={e.type}
              key={index}
              outlined={e.outlined}
              icon={e.icon}
              iconColor={e.iconColor}
              onClick={() => {
                navigate(e.path);
                if (e.onClick) {
                  e.onClick();
                }
              }}
            >
              {e.title}
            </Button>
          ))}
        </>
      )}
      <Popover placement="bottomRight" content={content} action="click">
        <Button type="default" style={{ paddingInline: '.65em', alignSelf: 'stretch' }}>
          <Icon icon="BiSearch" />
        </Button>
      </Popover>
    </>
  );
};

export default HeaderComponent;
