import rtkApis from '@rtkApis';
import parentApi from '../store/parentApi';
import { setProducts } from '../store/appSlice';
import tenantData from '@data';

const commonApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getActiveProducts: build.query({
      query: (query) => rtkApis.getActiveProducts.query(query),
      transformResponse: (response) => rtkApis.getActiveProducts?.transformer(response),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProducts(data));
        } catch (e) {
          dispatch(setProducts(tenantData.products));
        }
      },
    }),

    getMonthRules: build.query({
      query: () => rtkApis.getMonthRules.query(),
      transformResponse: (response) => {
        return response?.rules;
      },
      providesTags: ['month-rules'],
    }),
    getS3PreSignedUrl: build.query({
      query: ({ attachmentType = 'listing_image', associationKey, fileName }) => {
        return {
          url: `${
            attachmentType === 'listing_image' ? '/api/attachments/pre_signed_url' : '/api/assets/pre_signed_url'
          }?category=${attachmentType}&no_of_urls=${1}`,
          method: 'GET',
        };
      },
    }),
    deleteImageFromBank: build.mutation({
      query: (query) => rtkApis.deleteImageFromBank.query(query),
    }),
    getImageBank: build.query({
      query: (query) => rtkApis.getImageBank.query(query),
      transformResponse: (response) => rtkApis.getImageBank.transformer(response),
    }),
    getPackages: build.query({
      query: (query) => rtkApis.getPackages.query(query),
      transformResponse: (response) => rtkApis.getPackages?.transformer(response),
    }),
    getAccountManagerInfo: build.query({
      query: (user) => {
        const type = user?.agency ? 'agency' : 'user';
        const resource_id = user?.agency ? user.agency.id : user.id;
        return {
          url: `/api/dashboard/account_manager?type=${type}&resource_id=${resource_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response) => ({
        account_manager: {
          ...response,
          ...response.account_manager,
          assignee: {
            ...response.account_manager.assignee,
            phone: response.account_manager.assignee?.phone ? `+${response.account_manager.assignee.phone}` : null,
            name_l1: response.account_manager.assignee.name_tld,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetPackagesQuery,
  useGetMonthRulesQuery,
  useLazyGetPackagesQuery,
  useDeleteImageFromBankMutation,
  useLazyGetImageBankQuery,
  useLazyGetActiveProductsQuery,
  useGetActiveProductsQuery,
  useGetAccountManagerInfoQuery,
  useLazyGetS3PreSignedUrlQuery,
} = commonApis;
