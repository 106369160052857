import tenantTheme from '@theme';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '..';
import { LinkStyled } from './styled';
import { useSelector } from 'react-redux';

function LinkWithIcon(props) {
  const {
    link,
    linkTitle,
    style,
    color,
    iconColor = tenantTheme['primary-color'],
    showIconBeforeText,
    icon,
    className,
    fontWeight,
    ...rest
  } = props;
  const { rtl } = useSelector((state) => ({ rtl: state.app.AppConfig.rtl }));

  return (
    <LinkStyled to={link} style={{ color, fontWeight: fontWeight || 400, ...style }} className={className} {...rest}>
      {icon ? (
        <>
          {icon}
          {linkTitle}
        </>
      ) : (
        <>
          {showIconBeforeText && <Icon icon={rtl ? 'FiArrowUpLeft' : 'FiArrowUpRight'} color={iconColor} />}
          {linkTitle}
          {!showIconBeforeText && <Icon icon={rtl ? 'FiArrowUpLeft' : 'FiArrowUpRight'} color={iconColor} />}
        </>
      )}
    </LinkStyled>
  );
}

LinkWithIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  showIconBeforeText: PropTypes.bool,
};

export default LinkWithIcon;
