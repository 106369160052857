import tenantTheme from '@theme';
import { List, Typography } from 'antd';
import cx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hoverListingQualityDonut } from '../../services/analyticsService';
import { Button, Flex, Group, Heading } from '../common';
import { LegendHorizontal, PopoverContainer, QualityPopover } from './styled';
import DrawerPopover from '../common/drawerPopover/drawerPopover';
const { Title, Text } = Typography;

const ListingHealth = ({ dataToShow, overallPercentageScore, overallScoreIcon, statusId, headerBg }) => {
  const { isMobile } = useSelector((state) => state.app.AppConfig);
  const { user } = useSelector((state) => state.app.loginUser);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { t } = useTranslation();

  const renderPopOverHeader = () => {
    return (
      <Group
        template="auto auto"
        className={cx(!isMobile ? 'py-16 px-24' : '')}
        gap="10px"
        style={{
          alignItems: 'center',
          backgroundColor: `color-mix(in srgb, ${tenantTheme[headerBg]} 10%, #fff)`,
        }}
      >
        <div>
          <Title level={5} className="fz-16 mb-4" style={{ fontWeight: 700 }}>
            {t('Overall Quality')}
          </Title>
          <Text type="secondary">{t('Higher quality means more listing visibility & leads')}</Text>
        </div>

        <Flex align="center" justify="end" gap="4px">
          <LegendHorizontal>{overallScoreIcon}</LegendHorizontal>{' '}
        </Flex>
      </Group>
    );
  };

  const renderItem = (e) => {
    return (
      <List.Item className="py-16">
        <Flex justify="space-between" align={isMobile ? 'start' : 'center'} gap={isMobile ? '12px' : '24px'}>
          <div>
            <Heading
              className="color-gray-lighter mb-12"
              style={{ textTransform: 'uppercase', fontSize: '12px' }}
              as="h6"
            >
              {e.title}
            </Heading>

            <Flex gap="16px" align="center">
              {e?.scoreIcon}

              <div>
                <Title className="mb-0" level={5} style={{ fontSize: '14px' }}>
                  {e.subTitle}
                </Title>
                <Text type="secondary" className="fz-12">
                  {e.message}
                </Text>
              </div>
            </Flex>
          </div>

          {!!e.showButton && (
            <Button
              style={{ borderRadius: '6px' }}
              type="primaryOutlined"
              size={isMobile && 'small'}
              onClick={() => {
                setPopoverVisible(false);
                e.onClick();
              }}
            >
              {e.button}
            </Button>
          )}
        </Flex>
      </List.Item>
    );
  };

  return (
    <>
      <DrawerPopover
        headerStyle={{
          alignItems: 'start',
          backgroundColor: `color-mix(in srgb, ${tenantTheme?.[headerBg]} 10%, #fff)`,
        }}
        bodyStyle={{ '--ant-padding-lg': 0 }}
        getTooltipContainer={null}
        getPopupContainer={null}
        overlayClassName="quality-popupContainer"
        footer={null}
        height={'auto'}
        title={renderPopOverHeader()}
        onMouseEnter={() => hoverListingQualityDonut(user, statusId, overallPercentageScore)}
        content={
          <QualityPopover
            header={!isMobile && renderPopOverHeader()}
            itemLayout="vertical"
            dataSource={dataToShow}
            renderItem={(e) => (e.subTitle || e.message ? renderItem(e) : null)}
          />
        }
      >
        {overallScoreIcon}
      </DrawerPopover>
    </>
  );
};
export default ListingHealth;
