import tenantConstants from '@constants';
import InputOtp from '@onefifteen-z/react-input-otp';
import tenantTheme from '@theme';
import { Space } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useGetUserOtpViaKCMutation,
  useLazyGetUserOtpQuery,
  useVerifyOtpViaKCMutation,
  useVerifyUserOtpMutation,
} from '../../apis/user';
import { KC_REQUESTS } from '../../keycloak/requests';
import { getRequestHeaders } from '../../store/parentApi';
import { Button, DrawerModal, notification, TextWithIcon } from '../common';

const OtpVerificationModal = forwardRef(
  (
    { value, onCancelModal = () => {}, onSuccess = () => {}, onFail = () => {}, otpFor = 'mobile', okText, width },
    ref,
  ) => {
    const { t } = useTranslation();

    const [otpCode, setOtpCode] = useState('');
    const [timer, setTimer] = useState(60);
    const [otpModalShow, setOtpModalShow] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [verificationData, setVerificationData] = useState(false);
    const [otpForItem, setOtpFor] = useState(otpFor);
    const { user } = useSelector((state) => state.app.loginUser);
    const auth = useSelector((state) => state.auth.login);
    const [getOtp] = useLazyGetUserOtpQuery();
    const [getUserOtpViaKC] = useGetUserOtpViaKCMutation();

    const [verifyOTP, { isLoading: otpVerifyLoading, error: verifyOtpError }] = useVerifyUserOtpMutation();
    const [verifyOtpViaKC, { isLoading: otpVerifyViaKCLoading, error: verifyOtpViaKCError }] =
      useVerifyOtpViaKCMutation();

    useEffect(() => {
      let countdown;
      if (otpModalShow) {
        if (timer > 0) {
          countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
        }
      }
      return () => clearInterval(countdown);
    }, [timer, otpModalShow]);

    useEffect(() => {
      otpFor && setOtpFor(otpFor);
    }, [otpFor]);

    useImperativeHandle(ref, () => ({
      onVerify: onSendOtp,
    }));

    const mediums = { email: 'email', mobile: 'sms' };

    const onSendOtp = async (val, otpF = 'mobile') => {
      setOtpCode('');
      otpF && setOtpFor(otpF);
      const endpoint = KC_REQUESTS?.[otpF];
      const response = await (tenantConstants.KC_ENABLED
        ? getUserOtpViaKC({
            url: endpoint?.url,
            body: endpoint?.body(val, mediums[otpF]),
            headers: getRequestHeaders(auth, {}, endpoint?.url),
          })
        : getOtp({ id: user?.id, val, otpF }));
      if (response?.error) {
        notification.error(t(response?.error));
        onFail();
      } else {
        setOtpModalShow(true);
        tenantConstants.KC_ENABLED && setVerificationData(response?.data);
      }
    };

    const onResendOtp = async () => {
      const endpoint = KC_REQUESTS?.[`resend_verify_${otpForItem}`];
      setResendLoading(true);
      const response = await (tenantConstants.KC_ENABLED
        ? getUserOtpViaKC({
            url: endpoint?.url,
            body: endpoint?.body(verificationData, mediums[otpForItem]),
            headers: getRequestHeaders(auth, {}, endpoint?.url),
          })
        : getOtp(user?.id, value, otpForItem));
      if (response?.error) {
        setResendLoading(false);
        notification.error(t(response.error));
      } else {
        setResendLoading(false);
        setOtpCode('');
        setTimer(60);
      }
    };

    const onVerifyOtp = async () => {
      if (!otpCode) {
        notification.error('Please enter OTP to verify!');
      } else {
        const verifyEndpoint = KC_REQUESTS?.[`verify_${otpForItem}`];
        const data = { [otpForItem]: value, otp: otpCode };

        const response = await (tenantConstants.KC_ENABLED
          ? verifyOtpViaKC({
              url: verifyEndpoint?.url,
              body: verifyEndpoint?.body(verificationData, otpCode),
              headers: getRequestHeaders(auth, {}, verifyEndpoint?.url),
            })
          : verifyOTP({ id: user?.id, values: data, otpForItem }));

        if (response?.error) {
          notification.error(t(response.error));
          onFail();
          // notification.error(response?.response?.data?.error_description);
        } else {
          setOtpModalShow(false);
          setOtpCode('');
          notification.success(t(`${otpForItem === 'email' ? 'Email' : 'Phone Number'} Verified Successfully`));
          onSuccess();
        }
      }
    };

    const onCloseOtpModal = () => {
      setOtpModalShow(false);
      onCancelModal();
    };

    return (
      <DrawerModal
        title={t('OTP Verification')}
        visible={otpModalShow}
        destroyOnClose
        onCancel={onCloseOtpModal}
        width={width}
        footer={null}
      >
        <Space size={20} direction="vertical" className="text-center w-100">
          <div className="text-muted fs16">
            {t(`You've received a 4-digit code on: `)}
            <strong className="base-color">{value}</strong>
          </div>
          <InputOtp
            error={verifyOtpError || verifyOtpViaKCError}
            errorMessage={t('Incorrect OTP')}
            otpLength={4}
            onChange={(value) => setOtpCode(value)}
            value={otpCode}
            wrapperClass="flex justify-content-center dir-ltr"
          />
          <Space size={4} align="center" className="text-muted fs16 semiBold">
            {!!timer && (
              <TextWithIcon
                icon="RxCounterClockwiseClock"
                iconProps={{ size: 18, color: tenantTheme['primary-light-1'] }}
                textColor={tenantTheme['primary-light-1']}
                gap={6}
                title={timer?.toString()}
              />
            )}
          </Space>
          <div>
            <div className="text-muted">{t(`I didn’t receive any code`)}</div>
            <Button type="link" disabled={!!timer} onClick={onResendOtp} loading={resendLoading}>
              {t('Resend OTP')}
            </Button>
          </div>
          <Button
            type="primary"
            size="large"
            onClick={onVerifyOtp}
            block
            loading={otpVerifyLoading || otpVerifyViaKCLoading}
          >
            {okText || t('Continue')}
          </Button>
        </Space>
      </DrawerModal>
    );
  },
);

export default OtpVerificationModal;
