import { addBlobIdentifier, onFilesAdded, uploadFile } from '../../helpers/fileHelpers/imageUploadHelper';
import { Button, Group } from '../common';

import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploadItem from '../common/image-uploads/image-upload-item';
import Label from '../common/Label/Label';
import { useLazyGetS3PreSignedUrlQuery } from '../../apis/common';

const contentMappingForImages = ['image/jpeg', 'image/png'];

const handleFileAdd = async (images, setImages, newImages) => {
  const newImagesArray = await addBlobIdentifier(newImages);
  onFilesAdded(images, setImages, newImagesArray);
};

const ImageUpload = (props) => {
  const { t } = useTranslation();
  const {
    images,
    setImages,
    multi,
    attachmentType = 'generic',
    associationKey,
    btnText,
    imageDescription,
    ...rest
  } = props;
  const [getS3PreSignedUrl, { isLoading, isError }] = useLazyGetS3PreSignedUrlQuery();

  const renderInput = () => {
    return (
      <input
        id={`image-upload-${props.name}`}
        style={{ display: 'none' }}
        type="file"
        accept={contentMappingForImages.join(',')}
        multiple={multi}
        onChange={async (e) => {
          const newFiles = e.target.files;
          await handleFileAdd(images, (pics) => setImages(pics), newFiles);
        }}
        onClick={(event) => {
          event.target.value = '';
        }}
      />
    );
  };

  const renderUploadButton = () => {
    return (
      <Button
        onClick={() => {
          document.getElementById(`image-upload-${props.name}`).click();
        }}
        type="primaryOutlined"
        style={{ borderStyle: 'dashed' }}
        className="px-24"
      >
        {t(btnText) || t('Browse and Upload')}
      </Button>
    );
  };

  const removeImage = (imageItem) => {
    let newImages = [...images];
    newImages = newImages.filter((pic) => pic?.id != imageItem?.id || pic?.gallerythumb != imageItem?.gallerythumb);
    setImages(newImages);
  };

  const retryCallback = () => {
    images.map((pic) => {
      if (pic.inError && !pic.uploading && !pic.uploaded) {
        uploadFile(pic, attachmentType, 'nil', images, setImages, true, false, undefined);
      }
    });
  };

  const renderImages = () => {
    return images?.map((item) => (
      <ImageUploadItem
        key={item?.id || item?.gallerythumb}
        getS3PreSignedUrl={getS3PreSignedUrl}
        onRetry={retryCallback}
        removeImage={removeImage}
        item={item}
        files={images}
        setFiles={setImages}
        isSvg={false}
        makeBlob
        attachmentType={attachmentType}
        associationKey={null}
      />
    ));
  };
  return (
    <>
      <Group className={rest.className} template="initial" gap="8px">
        <Label>{props.label}</Label>
        {/* <Flex vertical> */}

        {renderImages()}
        {renderInput()}
        <div>{images.length === 0 ? renderUploadButton() : multi && renderUploadButton()}</div>
        {imageDescription && imageDescription}
        {/* </Flex> */}
      </Group>
    </>
  );
};

export default ImageUpload;
