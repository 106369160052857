import rtkApis from '@rtkApis';
import tenantTransformers from '@transformers';
import parentApi from '../store/parentApi';

const listingsApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getTruCheckStatuses: build.query({
      query: () => rtkApis.getTruCheckStatuses().url,
      transformResponse: (response) => {
        return response?.statuses?.flatMap((status) => status.dispositions);
      },
    }),
    getMyListings: build.query({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        try {
          const [summary, listings] = await Promise.all([
            baseQuery(`/api/listings/summary?${params?.mappedParams}`),
            baseQuery(`/api/listings/my_listings?${params?.mappedParams}`),
          ]);
          const listingsSummary = await summary;
          const myListings = await listings;
          return {
            data: tenantTransformers.getMyListingsData(listingsSummary?.data, myListings?.data, params?.userId),
          };
        } catch (error) {
          return { error: error?.message };
        }
      },
      providesTags: ['my-listings'],
    }),
    getListingsStats: build.query({
      queryFn: (params, api, extraOptions, baseQuery) =>
        rtkApis.getListingsStats.queryFn(params, api, extraOptions, baseQuery),
    }),
    listingDeleteReasons: build.query({
      query: (purposeId) => rtkApis.listingDeleteReasons.query(purposeId),
      transformResponse: (response) => {
        return response?.reasons?.length ? response?.reasons : [];
      },
    }),
    fetchPlotsByQuery: build.query({
      query: (query) => rtkApis.fetchPlotsByQuery.query(query),
      transformResponse: (response) => rtkApis.fetchPlotsByQuery.transformer(response),
    }),
    deleteListing: build.mutation({
      query: (body) => rtkApis.deleteListing?.query(body),
      async onQueryStarted(arg, api) {
        rtkApis.deleteListing?.afterSuccess(arg, api);
      },
    }),
    deleteListingFromDubizzle: build.mutation({
      query: (body) => rtkApis.deleteListingFromDubizzle?.query(body),
      async onQueryStarted(arg, api) {
        rtkApis.deleteListingFromDubizzle?.afterSuccess(arg, api);
      },
    }),
    getApplicableProducts: build.query({
      query: (query) => rtkApis.getApplicableProducts(query),
      transformResponse: (res, meta, args) => rtkApis.getApplicableProducts().transformer(res, meta, args),
    }),
    getUpsellData: build.query({
      queryFn: (params, api, extraOptions, baseQuery) =>
        rtkApis.getUpsellData.queryFn(params, api, extraOptions, baseQuery),
    }),
    applyProduct: build.mutation({
      query: (query) => rtkApis.applyProduct(query),
      async onQueryStarted(arg, api) {
        rtkApis.applyProduct()?.afterSuccess(arg, api);
      },
      invalidatesTags: ['current', 'credits'],
    }),
    updateAutoRenew: build.mutation({
      query: (query) => rtkApis.updateAutoRenew.query(query),
      transformResponse: (res, meta, arg) => rtkApis.updateAutoRenew.transformer(res, meta, arg),
      invalidatesTags: (result, error) => {
        return result ? ['my-listings'] : [];
      },
    }),
    fetchListingDetail: build.query({
      query: (params) => rtkApis.fetchListingDetail.query(params),
      transformResponse: (res) => rtkApis.fetchListingDetail.transformer(res),
    }),
    deleteStory: build.mutation({
      query: (query) => rtkApis.deleteStory.query(query),
      transformResponse: (res, meta, args) => rtkApis.deleteStory.transformer(res, meta, args),
      invalidatesTags: ['my-listings'],
    }),
    getAppliedAutoPlan: build.query({
      query: (params) => rtkApis.getAppliedAutoPlan.query(params),
    }),
    applyUtilizationPlan: build.mutation({
      query: (query) => rtkApis.applyUtilizationPlan.query(query),
    }),
    stopAutoPlan: build.mutation({
      query: (query) => rtkApis.stopAutoPlan.query(query),
      transformResponse: (res) => rtkApis.stopAutoPlan.transformer(res),
    }),
    postPackageOnListing: build.mutation({
      query: (params) => rtkApis.postPackageOnListing?.query(params),
      async onQueryStarted(arg, api) {
        rtkApis.postPackageOnListing?.afterSuccess(arg, api);
      },
    }),
    fetchServicesDetails: build.query({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const { selectedAction, user } = params;

        const apiCalls = [baseQuery(''), baseQuery('')];
      },
    }),
    getListingCardDetail: build.query({
      query: (params) => rtkApis.getListingCardDetail(params),
    }),
    getOffplanProjects: build.query({
      query: (params) => rtkApis.getOffplanProjects(params),
    }),
  }),
});

export const {
  useStopAutoPlanMutation,
  useDeleteListingFromDubizzleMutation,
  useLazyFetchPlotsByQueryQuery,
  useGetTruCheckStatusesQuery,
  useApplyUtilizationPlanMutation,
  useLazyGetAppliedAutoPlanQuery,
  useDeleteStoryMutation,
  useLazyFetchListingDetailQuery,
  useUpdateAutoRenewMutation,
  useLazyListingDeleteReasonsQuery,
  useDeleteListingMutation,
  useGetListingsStatsQuery,
  useGetMyListingsQuery,
  useLazyGetApplicableProductsQuery,
  useGetApplicableProductsQuery,
  useApplyProductMutation,
  usePostPackageOnListingMutation,
  useLazyGetListingsStatsQuery,
  useGetUpsellDataQuery,
  useLazyFetchServicesDetailsQuery,
  useLazyGetListingCardDetailQuery,
  useGetOffplanProjectsQuery,
} = listingsApis;

export default listingsApis;
