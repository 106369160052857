import tenantPayloads from '@payloads';
import rtkApis from '@rtkApis';
import parentApi from '../store/parentApi';
import { convertQueryObjToString } from '../utility/urlQuery';

const postListingApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    postNewListing: build.mutation({
      query: (params) => rtkApis.postNewListing.query(params),
    }),
    getListingDetail: build.query({
      query: (params) => rtkApis.getListingDetail.query(params),
      transformResponse: (res, meta, args) => rtkApis.getListingDetail.transformer(res, meta, args),
    }),
    getUpsellDetail: build.query({
      queryFn: (params, api, extraOptions, baseQuery) =>
        rtkApis.getUpsellDetail.queryFn(params, api, extraOptions, baseQuery),
    }),
    getPriceCheck: build.query({
      query: (params) => rtkApis.getPriceCheck.query(params),
    }),
    getLicenseValidity: build.query({
      query: (adLicense) => `/api/listings/new_listing_data?ad_license_number=${adLicense}`,
    }),
    // getAutoGeneratedContent: build.query({
    //   query: (body) =>
    //     `/api/listings/${body?.listingId}/generate_content?${convertQueryObjToString(tenantPayloads.autoGeneratedContentPayload(body))}`,
    // }),
    getAutoGeneratedContent: build.query({
      query: (body) => rtkApis.getAutoGeneratedContent.query(body),
    }),
    updateListing: build.mutation({
      query: (params) => rtkApis.updateListing.query(params),
    }),
    getLocationsBasedOnNational: build.query({
      query: (nationalAddress) => `/api/locations/google_location?address=${nationalAddress}`,
    }),
    postSavedListing: build.mutation({
      query: (query) => rtkApis.postSavedListing.query(query),
      async onQueryStarted(arg, api) {
        rtkApis.postSavedListing?.afterSuccess(arg, api);
      },
      transformResponse: (response, meta, arg) => rtkApis.postSavedListing.transformer(response, meta, arg),
    }),
    hideUnhideListings: build.mutation({
      query: (query) => rtkApis.hideUnhideListings.query(query),
      async onQueryStarted(arg, api) {
        rtkApis.hideUnhideListings?.afterSuccess(arg, api);
      },
      transformResponse: (response) => rtkApis.hideUnhideListings.transformer(response),
    }),
    getAmenities: build.query({
      query: (purposeId) => rtkApis.getAmenities.query(purposeId),
      transformResponse: (response) => rtkApis.getAmenities.transformer(response),
    }),
    fetchCrossCityDetail: build.query({
      query: (params) => rtkApis.fetchCrossCityDetail.query(params),
      transformResponse: (response) => rtkApis.fetchCrossCityDetail.transformer(response),
    }),
    getApplicableLicenses: build.query({
      query: (params) => '/api/licenses/applicable_licenses',
      transformResponse: (response) =>
        response?.data?.licenses?.length ? response?.data?.licenses?.map((e) => ({ ...e, key: e?.id })) : [],
    }),
  }),
});

export const {
  usePostNewListingMutation,
  useLazyGetPriceCheckQuery,
  useHideUnhideListingsMutation,
  usePostSavedListingMutation,
  useLazyGetListingDetailQuery,
  useGetListingDetailQuery,
  useLazyGetLicenseValidityQuery,
  useLazyGetAutoGeneratedContentQuery,
  useUpdateListingMutation,
  useLazyGetLocationsBasedOnNationalQuery,
  useLazyGetAmenitiesQuery,
  useLazyFetchCrossCityDetailQuery,
  useLazyGetUpsellDetailQuery,
  useGetUpsellDetailQuery,
  useGetApplicableLicensesQuery,
} = postListingApis;
