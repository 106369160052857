import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteAgencyUserModal } from '../../../../../components/delete-agency-user/deleteAgencyUserModal';
import { UserForm } from '../../../../../components/forms/AgencyUserForm';
import TableActions from '../../../../../components/table/table-actions/table-actions';
import TenantComponents from '@components';

//TODO RTKQ Row action component can be common
export const AgencyUserRowActions = (props) => {
  const { userId, agencyId, isAdmin, name, deleteModalData, quotaCredit, creditsAvailable } = props;
  const { user } = useSelector((state) => state.app.loginUser);
  const [showUserForm, setShowUserForm] = useState(false);
  const [showManageQuota, setShowManageQuota] = useState(false);
  const confirmationModalRef = useRef();
  return (
    !isAdmin &&
    userId != user.id && (
      <>
        <TableActions
          id={`${agencyId}-${userId}`}
          actionsList={[
            {
              iconType: 'edit',
              onClick: () => {
                setShowUserForm(true);
              },
            },
            {
              iconType: 'manage-products',
              onClick: () => {
                setShowManageQuota(true);
              },
            },
            {
              iconType: 'delete',
              onClick: () => {
                confirmationModalRef?.current && confirmationModalRef.current.showModal();
              },
            },
          ]}
        />
        <UserForm
          isVisible={showUserForm}
          setIsVisible={setShowUserForm}
          agencyId={agencyId}
          userId={userId}
          isAdmin={isAdmin}
        />
        <TenantComponents.ManageQuota
          isVisible={showManageQuota}
          setIsVisible={setShowManageQuota}
          agencyId={agencyId}
          userId={userId}
          name={name}
          quotaCredit={quotaCredit}
        />

        <DeleteAgencyUserModal
          confirmationModalRef={confirmationModalRef}
          deleteModalData={deleteModalData}
          userId={userId}
          agencyId={agencyId}
        />
      </>
    )
  );
};
