import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tenantConstants from '@constants';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Icon, notification } from '../components/common';
import { TIME_DATE_FORMAT } from '../constants/formats';
import { submitUpgradePopupClickEvent } from '../services/analyticsService';
import { getTimeDateString } from '../utility/date';
import { useCreateCartMutation } from '../apis/cart';
import { useApplyProductMutation, useLazyGetApplicableProductsQuery } from '../apis/listings';

export const useApplyProductModalDataOman = (user, deductionModalRef, serviceDataRef) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('credits');
  const [action, setAction] = useState(null);
  const [listingData, setListingData] = useState(null);
  const { selectedUser: selectedUser } = useSelector((state) => state.app.Dashboard);
  const isMemberArea = useSelector((state) => state.app.AppConfig.isMemberArea);
  const [getApplicableProducts, {}] = useLazyGetApplicableProductsQuery();
  const [applyProduct, _] = useApplyProductMutation();
  const [createCartUpSell] = useCreateCartMutation();

  const getServicePayload = () => {
    const values = serviceDataRef?.current?.getValues();
    return values
      ? {
          [action?.applicableProduct?.slug]: {
            comments: values?.comments,
            requested_at: getTimeDateString(values?.requested_at, TIME_DATE_FORMAT, false, true),
          },
        }
      : null;
  };

  const onApplyProduct = async (productId, listingId) => {
    serviceDataRef?.current?.handleSubmit();
    const errors = await serviceDataRef?.current?.getErrors();
    if (!errors) {
      setConfirmationLoading(true);
      const add_on = getServicePayload();
      const response = await applyProduct({
        listingId,
        body: {
          product_ids: [productId],
          ...(add_on && { add_on }),
        },
      });
      if (response) {
        setConfirmationLoading(false);
        deductionModalRef?.current && deductionModalRef.current.hideQuotaCreditModal();
        setActionLoading(false);
        if (response?.error) {
          notification.error(response?.error);
        } else if (response?.data?.listing) {
          notification.success(t('Listing Updated Successfully'));
        }
      }
    }
  };

  const createCart = async (productId, listingId, expiryDays, creditsAmount, purchaseFullCredits) => {
    serviceDataRef?.current?.handleSubmit();
    const errors = await serviceDataRef?.current?.getErrors();
    const cart = {
      total_credits: creditsAmount,
      listing_expiry_days: expiryDays,
      source: 'profolio',
      listing_id: listingId,
      cart_details_attributes: [
        { item_id: productId, item_type: 'Product', source: 'profolio', quantity: 1, required_credits: creditsAmount },
      ],
      purchased_full_credits: purchaseFullCredits,
    };
    const add_on = getServicePayload();
    if (!errors) {
      setConfirmationLoading(true);
      const response = await createCartUpSell({ cart, ...(add_on && { add_on }) });
      if (response) {
        setConfirmationLoading(false);
        deductionModalRef?.current && deductionModalRef.current.hideQuotaCreditModal();
        setActionLoading(false);
        if (response.error) {
          notification.error(response.error);
        } else {
          const isBasicListing =
            action?.applicableProduct?.slug === 'basic-listing-dubizzle' ||
            action?.applicableProduct?.slug === 'basic-listing';

          const postListing = isBasicListing ? true : false;
          const upgradeListing = isBasicListing ? false : true;

          navigate(
            `/checkout?cart_id=${response?.data?.cart?.id}&upgrade_listing=${upgradeListing}&post_listing=${postListing}`,
            {
              state: {
                disposition: listingData?.disposition?.slug,
                status: listingData?.status?.slug,
                interactedFrom: 'manage-listings',
              },
            },
          );
        }
      }
    }
  };
  const onSuccessQuotaCreditModal = () => {
    const isUsingCredits = selectedPaymentOption === 'credits';

    if (user?.isCurrencyUser) {
      if (action?.isSufficient && isUsingCredits) {
        onApplyProduct(action?.applicableProduct?.product_id, action?.listing_id);
      } else {
        createCart(
          action?.applicableProduct?.product_id,
          action?.listing_id,
          action?.expiryDays,
          isUsingCredits ? action?.creditsDiff : action?.required_credits,
          !isUsingCredits,
        );
      }
    } else {
      onApplyProduct(action?.applicableProduct?.product_id, action?.listing_id);
    }
  };

  const getDeduction = async (deductionType, listing, product, platformSlug = null) => {
    setListingData(listing);
    setActionLoading((prev) => ({ ...prev, [`${listing?.slug}${product?.name}`]: true }));
    const response = await getApplicableProducts({
      requestParams: { listing_id: listing?.property_id },
      platformProductSlugs: { [platformSlug]: product?.slug },
    });
    if (response) {
      if (response?.error) {
        setActionLoading(false);
        notification.error(response.error);
      } else if (!response?.data?.[platformSlug]?.applicableProduct) {
        setActionLoading(false);
        notification.error(t('Product can not be applied'));
      } else if (!user?.isCurrencyUser) {
        if (
          response?.data?.[platformSlug]?.available_credits >=
          response?.data?.[platformSlug]?.applicableProduct?.quantity_required
        ) {
          setAction({ ...response?.data?.[platformSlug], platform: listing?.slug, expiryDays: listing?.expiryDays });
          deductionModalRef?.current && deductionModalRef.current.showQuotaCreditModal();
        } else {
          setActionLoading(false);
          notification.error(t(`You don't have enough credits`));
        }
      } else {
        const creditsDiff =
          response?.data?.[platformSlug]?.available_credits -
          response?.data?.[platformSlug]?.applicableProduct?.credits_required?.[listing?.expiryDays];
        setAction({
          ...response?.data?.[platformSlug],
          platform: listing?.slug,
          expiryDays: listing?.expiryDays,
          isSufficient: !!(creditsDiff >= 0),
          creditsDiff: Math.abs(creditsDiff),
          requiredCredits: response?.data?.[platformSlug]?.applicableProduct?.credits_required?.[listing?.expiryDays],
          required_credits:
            !!(creditsDiff >= 0) || selectedPaymentOption === 'currency'
              ? response?.data?.[platformSlug]?.applicableProduct?.credits_required?.[listing?.expiryDays]
              : response?.data?.[platformSlug]?.applicableProduct?.credits_required?.[listing?.expiryDays] -
                response?.data?.[platformSlug]?.applicableProduct?.availableCredits,
          availableCredits: response?.data?.[platformSlug]?.applicableProduct?.availableCredits,
        });
        deductionModalRef?.current && deductionModalRef.current.showQuotaCreditModal();
      }
    }
  };

  const paymentOptions = useMemo(
    () => [
      {
        key: 'credits',
        title: t('Credits'),
        description: t('Pay using your available credits to get the upgrade instantly'),
        value: action?.requiredCredits,
        prefix: <Icon icon="IconTotalCredit"></Icon>,
      },
      {
        key: 'currency',
        title: t('Credit or Debit Card'),
        description: (
          <span>
            {t('Pay online in ')} {tenantConstants.CURRENCY_SYMBOL()} {t('using other payment methods at checkout')}
          </span>
        ),
        value: action?.requiredCredits * action?.credit_unit_price,
        prefix: tenantConstants.CURRENCY_SYMBOL(),
      },
    ],
    [action],
  );

  const onChangePaymentOption = (e) => {
    setSelectedPaymentOption(e.key);
  };

  const onCancelModal = () => {
    setActionLoading(null);
    serviceDataRef?.current?.resetForm();
    deductionModalRef?.current?.hideQuotaCreditModal();
  };

  return {
    selectedPaymentOption,
    confirmationLoading,
    deductionModalRef,
    paymentOptions,
    action,
    actionLoading,
    onSuccessQuotaCreditModal,
    getDeduction,
    onChangePaymentOption,
    onCancelModal,
    onApplyProduct,
  };
};
