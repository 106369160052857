import tenantTheme from '@theme';
import { Divider, Row, Space } from 'antd';
import cx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, EmptyState, Group, LinkWithIcon, LoaderWrapper, Number, TextWithIcon } from '../../common';
import dotAnimation from '../../common/bayut.json';
import Statistic from '../../common/statistic';
import { ListingBreakdownWidgetSkeleton } from './listing-breakdown-skeleton';
import parentApi from '../../../store/parentApi';

function ListingBreakdown(props) {
  const { t } = useTranslation();
  const isMobile = useSelector((state) => state.app.AppConfig.isMobile);
  const {
    platform,
    brandColor,
    style,
    linear,
    icon,
    trends = true,
    cardBodyStyle,
    minHeight,
    user,
    showLink = true,
  } = props;

  const {
    data,
    isLoading: loading,
    isFetching,
    error,
    refetch: onErrorRetry,
  } = parentApi?.[`useGetListingsBreakdownWidgetDataFor${platform}Query`]?.(
    { ...(user?.id === -1 ? null : { [`q[user_id_eq]`]: user?.id?.toString() }) },
    {
      skip: !user?.id || !platform,
      refetchOnMountOrArgChange: true,
    },
  ) || {};

  const renderListingCardTitle = () => {
    return (
      <TextWithIcon
        className={cx(isMobile ? 'fz-14' : 'fz-16')}
        title={t(data?.[platform]?.title)}
        icon={icon}
        fontWeight={700}
        loading={!data?.[platform] && loading}
        loadingProps={{ avatarSize: 24, rectSize: 'small' }}
        iconProps={{ iconBackgroundColor: '#fff', hasBackground: true }}
        style={isMobile ? { flexDirection: 'row' } : {}}
      />
    );
  };
  const renderCardExtraContent = () => {
    return (
      data?.[platform]?.link_data &&
      showLink && (
        <LinkWithIcon
          linkTitle={t(data?.[platform]?.link_data.text)}
          link={data?.[platform]?.link_data.to}
          color={tenantTheme.gray700}
          iconColor={brandColor}
        />
      )
    );
  };

  return (loading && isFetching) || !platform ? (
    <ListingBreakdownWidgetSkeleton isMobile={isMobile} style={style} />
  ) : error ? (
    <Card bodyStyle={{ padding: '16px 24px' }}>
      <EmptyState
        type="listings-count-dashboard"
        message={error}
        buttonLoading={loading}
        onClick={onErrorRetry}
        accentColor={brandColor}
      />
    </Card>
  ) : (
    <>
      <LoaderWrapper loading={!loading && isFetching}>
        <Card
          className="flex justify-content-center flex-grow"
          style={{
            '--primary-color': brandColor,
            minHeight: minHeight || 'calc(100% )',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'start',
            '--flex-direction': 'row',
            '--title-padding': '0px',
            ...style,
          }}
          headStyle={{
            borderBottom: 'none',
          }}
          bodyStyle={cardBodyStyle ? cardBodyStyle : { padding: isMobile ? '16px 12px' : '16px 24px' }}
          title={renderListingCardTitle()}
          extra={renderCardExtraContent()}
        >
          <div>
            {linear ? (
              <Group template="repeat(3, 1fr)" gap={isMobile ? '24px 8px' : '24px 28px'}>
                <Statistic
                  lottieOptions={{ width: 32, height: 32, animationData: dotAnimation }}
                  title={t(data?.[platform]?.total_title)}
                  formatter={
                    <Number value={data?.[platform]?.total_value ? data?.[platform]?.total_value : 0} compact={false} />
                  }
                  value={data?.[platform]?.total_value ? data?.[platform]?.total_value : 0}
                  leader
                  className={cx(isMobile && 'span-all')}
                  trends={trends}
                />
                {data?.[platform]?.purposes?.map((item) => {
                  return (
                    <Statistic
                      key={item?.id}
                      icon={item?.icon}
                      iconProps={{ ...item?.iconProps, hasBackground: true }}
                      title={t(item?.title)}
                      formatter={<Number value={item?.value ? item.value : 0} compact={false} />}
                      value={item?.value ? item.value : 0}
                      fontSize="16px"
                      trends={trends}
                    />
                  );
                })}
                {data?.[platform]?.products?.map((item) => {
                  return (
                    <Statistic
                      key={item?.id}
                      icon={item?.icon}
                      iconProps={{ ...item?.iconProps, hasBackground: true }}
                      title={t(item?.title)}
                      formatter={<Number value={item?.value ? item.value : 0} compact={false} />}
                      value={item?.value ? item.value : 0}
                      fontSize="16px"
                      trends={trends}
                    />
                  );
                })}
              </Group>
            ) : (
              <Group template="24% auto" gap="0px">
                <Space.Compact>
                  <Statistic
                    lottieOptions={{ width: 32, height: 32, animationData: dotAnimation }}
                    title={t(data?.total_title)}
                    formatter={<Number value={data?.total_value ? data?.total_value : 0} compact={false} />}
                    value={data?.total_value ? data?.total_value : 0}
                    leader
                    trends={trends}
                  />
                </Space.Compact>
                <Row style={{ gap: 16 }}>
                  <Divider type="vertical" style={{ height: '90%', alignSelf: 'center', margin: 0 }} />
                  <Group template="repeat(auto-fit, minmax(min(7ch, 100%), 1fr))" gap="16px 8px" style={{ flex: 2 }}>
                    {data?.purposes?.map((item) => {
                      return (
                        <Statistic
                          key={item?.id}
                          icon={item?.icon}
                          iconProps={{ ...item?.iconProps, hasBackground: true }}
                          title={t(item?.title)}
                          formatter={<Number value={item?.value ? item.value : 0} compact={false} />}
                          value={item?.value ? item.value : 0}
                          fontSize="16px"
                          trends={trends}
                        />
                      );
                    })}
                  </Group>
                  <Divider type="vertical" style={{ height: '90%', alignSelf: 'center', margin: 0 }} />
                  <Group template="repeat(auto-fill, minmax(min(9ch, 100%), 1fr))" gap="16px 8px" style={{ flex: 3 }}>
                    {data?.[platform]?.products?.map((item) => {
                      return (
                        <Statistic
                          key={item?.id}
                          icon={item?.icon}
                          iconProps={{ ...item?.iconProps, hasBackground: true }}
                          title={t(item?.title)}
                          formatter={<Number value={item?.value ? item.value : 0} compact={false} />}
                          value={item?.value ? item.value : 0}
                          fontSize="16px"
                          trends={trends}
                        />
                      );
                    })}
                  </Group>
                </Row>
              </Group>
            )}
          </div>
        </Card>
      </LoaderWrapper>
    </>
  );
}

ListingBreakdown.propTypes = {
  listingBreakdownData: PropTypes.object,
  loading: PropTypes.bool,
  brandColor: PropTypes.string,
  error: PropTypes.string,
  onErrorRetry: PropTypes.func,
};

export default ListingBreakdown;
