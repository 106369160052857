import rtkApis from '@rtkApis';
import parentApi from '../store/parentApi';
import tenantConstants from '@constants';
import { DATE_BEFORE_TIME_FORMAT } from '../constants/formats';
import tenantUtils from '@utils';
import tenantData from '@data';
import { mapQueryParams } from '../utility/utility';

const mailDetailMapper = (item) => {
  return {
    id: item?.id,
    enquiry_about: {
      id: item?.listing?.id,
      ...(item?.listing?.listing_purpose && { purpose: item?.listing?.listing_purpose }),
      type: item?.listing?.listing_type,
      area: { value: item?.listing?.area_unit?.value, unit: item?.listing?.area_unit?.name },
      location: {
        ...item?.listing?.location,
        breadcrumb: item?.listing?.location?.breadcrumb
          ?.reverse()
          ?.filter((e) => e?.level > 1)
          ?.map((e) => tenantUtils.getLocalisedString(e, 'title'))
          ?.join(', '),
      },
      image: item?.listing?.image,
      price: { value: item?.listing?.price, currency: tenantConstants.CURRENCY },
      details: {
        ...item?.listing?.rega_details?.property_specs,
        listingSpecs: tenantData?.getListingSpecs(item?.listing),
        listing_type: item?.listing?.listing_type,
        regaId: item?.listing?.rega_details?.license_info?.ad_license_number,
        regaExpiryDate: item?.listing?.platforms?.rega_expiry_date,
      },
      ...(item?.listing?.health && {
        health: item?.listing?.health,
      }),
    },
    details: {
      name: item?.user_name,
      email: item?.user_email,
      phone: item?.user_phone,
    },
    date: { value: item?.datetime, format: DATE_BEFORE_TIME_FORMAT },
    emailBody: { message: item?.email_body, isExpandable: false },
  };
};
const inboxEndpoints = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getMailCount: build.query({
      query: (userId) => {
        return rtkApis.getMailCount.query(userId);
      },
      transformResponse: (response) => rtkApis.getMailCount?.transformer(response),
    }),
    getMailbox: build.query({
      query: (queryParams) => {
        return rtkApis.getMailbox.query(queryParams);
      },
      transformResponse: (response) => rtkApis.getMailbox?.transformer(response),
    }),
    postReply: build.mutation({
      query: (params) => {
        return rtkApis.postReply.query(params);
      },
    }),
    deleteMailThread: build.mutation({
      query: (params) => {
        return rtkApis.deleteMailThread.query(params);
      },
    }),
    untrashThead: build.mutation({
      query: (params) => {
        return rtkApis.untrashThead.query(params);
      },
    }),
    getMessageDetail: build.query({
      query: (messageId) => {
        return rtkApis.getMessageDetail.query(messageId);
      },
    }),
    getEmailLeads: build.query({
      query: (params) =>
        `/api/dashboard/email_leads?${mapQueryParams({ page: params?.page || 1, platform_id: params?.platform })}`,
      transformResponse: (response) => {
        const mappedList = response?.email_leads?.length ? response?.email_leads?.map(mailDetailMapper) : [];

        return {
          table: [
            {
              title: 'Property',
              component: 'ListingPurpose',
              dataIndex: 'enquiry_about',
              key: 'enquiry_about',
              width: 420,
            },
            {
              title: 'Details',
              dataIndex: 'details',
              key: 'details',
              component: 'StaffDetails',
              width: 280,
            },
            {
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
              component: 'Date',
              width: 180,
            },
            {
              title: 'Message',
              dataIndex: 'emailBody',
              key: 'emailBody',
              component: 'ExpandableMessage',
            },
          ],
          list: mappedList,
          pagination: tenantUtils.getPaginationObject(response?.pagination),
        };
      },
      providesTags: ['email-leads'],
    }),

    getLeads: build.query({
      query: (params) => rtkApis.getLeads.query(params),
      transformResponse: (response, meta, { refsObject }) => rtkApis.getLeads.transformer(response, refsObject),
    }),
    getLeadDetail: build.query({
      query: (params) => rtkApis.getLeadDetail.query(params),
      transformResponse: (response) => rtkApis.getLeadDetail.transformer(response),
    }),
    getTasksTypes: build.query({
      query: (params) => rtkApis.getTasksTypes.query(params),
      transformResponse: (response) => rtkApis.getTasksTypes.transformer(response),
    }),
    getTasksSubTypes: build.query({
      query: (params) => rtkApis.getTasksSubTypes.query(params),
      transformResponse: (response) => rtkApis.getTasksSubTypes.transformer(response),
    }),
    getLeadListings: build.query({
      query: (params) => rtkApis.getLeadListings.query(params),
      transformResponse: (response) => rtkApis.getLeadListings.transformer(response),
    }),
    getTasks: build.query({
      query: (params) => rtkApis.getTasks.query(params),
      transformResponse: (response) => rtkApis.getTasks.transformer(response),
    }),
    getLeadInterests: build.query({
      query: (params) => rtkApis.getLeadInterests.query(params),
      transformResponse: (response) => rtkApis.getLeadInterests.transformer(response),
    }),
    addLeadInterests: build.mutation({
      query: (params) => rtkApis.addLeadInterests.query(params),
    }),
    updateLeadInterest: build.mutation({
      query: (params) => rtkApis.updateLeadInterest.query(params),
    }),
    addTask: build.mutation({
      query: (params) => rtkApis.addTask.query(params),
    }),
    updateLeadName: build.mutation({
      query: (params) => rtkApis.updateLeadName.query(params),
    }),
    getUserActiveListings: build.query({
      query: (params) => rtkApis.getUserActiveListings.query(params),
      transformResponse: (response) => rtkApis.getUserActiveListings.transformer(response),
    }),
    getLeadCallRecording: build.query({
      query: (params) => rtkApis.getLeadCallRecording.query(params),
    }),
    getProductStats: build.query({
      queryFn: (params, api, extraOptions, baseQuery) =>
        rtkApis['getProductStats']?.queryFn(params, api, extraOptions, baseQuery),
    }),
  }),
});

export const {
  useLazyGetMailCountQuery,
  useDeleteMailThreadMutation,
  useLazyGetMessageDetailQuery,
  useUntrashTheadMutation,
  usePostReplyMutation,
  useLazyGetMailboxQuery,
  useGetEmailLeadsQuery,
  useGetLeadsQuery,
  useGetProductStatsQuery,
  useLazyGetLeadListingsQuery,
  useGetTasksTypesQuery,
  useLazyGetTasksSubTypesQuery,
  useLazyGetUserActiveListingsQuery,
  useAddLeadInterestsMutation,
  useAddTaskMutation,
  useLazyGetLeadDetailQuery,
  useLazyGetLeadInterestsQuery,
  useLazyGetTasksQuery,
  useUpdateLeadNameMutation,
  useLazyGetLeadCallRecordingQuery,
  useUpdateLeadInterestMutation,
} = inboxEndpoints;
