import * as yup from 'yup';
import tenantConstants from '@constants';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { regex } from '../../../../constants/regex';

export const getValidationSchema = (listing) => {
  const { t } = useTranslation();
  return yup.object().shape({
    purpose: yup.number().required(t('Please select purpose')),
    property_type: yup.number().required(t('Please select property type')),
    project: yup
      .object()
      .nullable()
      .test({
        message: t('Please select a project'),
        test: function (value) {
          if (this.parent.project_selection && !value?.id) return false;
          return true;
        },
      }),
    location_select: yup.object().shape({
      city: yup
        .object()
        .required(t(`Please select a ${tenantConstants.USER_LOCATIONS.label}`))
        .test({
          message: t(`Please select a ${tenantConstants.USER_LOCATIONS.label}`),
          test: (value) => value?.location_id,
        }),
      location: yup
        .object()
        .required(t('Please select a location'))
        .test({ message: t('Please select a location'), test: (value) => value?.location_id }),
    }),
    area: yup
      .object()
      .required(t('Please enter area and unit'))
      .test({
        message: t('Please enter valid area'),
        test: function (value) {
          if (!value?.value) return false;
          if (isNaN(Number(value?.value))) return false;
          else return true;
        },
      })
      .test({
        message: t('Please select a unit'),
        test: function (value) {
          if (!value || !value?.unit) return false;
          return true;
        },
      }),
    price: yup
      .object()
      .required(t('Please enter price'))
      .test({
        message: t('Please enter valid price'),
        test: function (value) {
          if (this.parent.purpose != 1) return true;
          if (!value?.value) return false;
          if (isNaN(Number(value?.value))) return false;
          return true;
        },
      }),
    monthly_rent: yup
      .object()
      .required(t('Please enter price'))
      .test({
        message: t('Please enter valid price'),
        test: function (value) {
          if (this.parent.purpose != 2) return true;
          if (!value?.value) return false;
          if (isNaN(Number(value?.value))) return false;
          return true;
        },
      }),
    advance_amount: yup
      .object()
      .required(t('Please enter advance amount'))
      .test({
        message: t('Please enter advance amount'),
        test: function (value, ctx) {
          if (this.parent.installment_available) {
            if (!this.parent.price.value)
              return this.createError({ message: t('Please enter total price'), path: 'advance_amount' });
            if (isNaN(Number(this.parent.price.value)))
              return this.createError({ message: t('Please enter valid price'), path: 'advance_amount' });
            if (isNaN(Number(value?.value))) return false;
            if (!value?.value)
              return this.createError({ message: t('Please enter advance amount'), path: 'advance_amount' });
            if (Number(this.parent.price.value) <= Number(value.value))
              return this.createError({
                message: t('Advance amount should be less than the total price'),
                path: 'advance_amount',
              });
          }
          return true;
        },
      }),
    monthly_installment: yup
      .object()
      .required('Please enter monthly installment')
      .test({
        message: t('Please enter valid number'),
        test: function (value, ctx) {
          if (this.parent.installment_available) {
            if (!this.parent.price.value)
              return this.createError({ message: t('Please enter total price'), path: 'monthly_installment' });
            if (isNaN(Number(this.parent.price.value)))
              return this.createError({ message: t('Please enter valid price'), path: 'monthly_installment' });
            if (isNaN(Number(value?.value))) return false;
            if (!value?.value)
              return this.createError({ message: t('Please enter advance amount'), path: 'monthly_installment' });
            const allTotal =
              Number(this.parent.monthly_installment.value) * Number(this.parent.no_of_installments) +
              Number(this.parent.advance_amount.value);
            if (isNaN(allTotal) || allTotal != Number(this.parent.price.value)) {
              return this.createError({
                message: t('Installment amounts sum should be equal to the total price'),
                path: 'no_of_installments',
              });
            }
          }
          return true;
        },
      }),
    no_of_installments: yup
      .number()
      .typeError('Please enter a valid number')
      .test({
        message: t('Please enter no of installments'),
        test: function (value) {
          if (this.parent.installment_available) {
            if (isNaN(Number(value))) return false;
            if (!value) return false;
          }
          return true;
        },
      }),
    property_title: yup
      .string()
      .required(t('Please enter property title'))
      .test('contains-alpha', t('Title must contain at least one alphabetic character'), (val) =>
        regex.onlyAlphabets.test(val),
      )
      .test('len', t('Minimum of 5 characters required'), (val) => val?.length >= 5),
    property_description: yup
      .string()
      .required(t('Please enter property description'))
      .test('contains-alpha', t('Description must contain at least one alphabetic character'), (val) =>
        regex.onlyAlphabets.test(val),
      )
      .test('len', t('Minimum of 20 characters required'), (val) => val?.length >= 20),
    property_images: yup
      .array()
      .nullable()
      .test({
        message: t('Maximum 50 images can be selected'),
        test: (arr) => !!arr && arr.length < 51,
      }),
    videos: yup.array().of(
      yup
        .object()
        .nullable()
        // .test({ message: 'Title is required', test: value => value?.title })
        .test({
          message: t('Please enter a valid url'),
          test: (value) => {
            if (!value.hasOwnProperty('_destroy') || !value.hasOwnProperty('url')) {
              return /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?(?=.*v=([A-Za-z0-9_-]+))(?:\S+)?|embed\/([A-Za-z0-9_-]+)|v\/([A-Za-z0-9_-]+)|shorts\/([A-Za-z0-9_-]+))|youtu\.be\/([A-Za-z0-9_-]+))(?:\?.*)?$/.test(
                value?.url,
              );
            } else {
              return true;
            }
          },
        }),
    ),
    email: yup
      .string()
      .required(t('Please enter email'))
      .test('', t('Invalid email format'), function (value) {
        const isValidEmail = regex.emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        return true;
      }),
    landline: yup
      .string()
      .test('format', t('Please enter a valid phone'), (value) => isValidPhoneNumber(value ? value : '-')),
    mobile: yup.array().of(
      yup
        .string()
        .required(t('Please enter phone'))
        .test('format', t('Please enter a valid phone'), (value) => isValidPhoneNumber(value ? value : '-')),
    ),
    platform_selection: yup.object().test({
      message: t('Please select a platform'),
      test: function (value) {
        let isValid;
        Object.keys(value).forEach((e) => {
          if (value?.[e]?.checked) {
            isValid = true;
          }
        });
        if (isValid || !!listing) {
          return true;
        } else {
          return false;
        }
      },
    }),
  });
};
