import tenantConstants from '@constants';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isProduction } from '../utility/env';

const useAppServices = () => {
  const { user } = useSelector((state) => state.app.loginUser);
  const { isMemberArea, locale } = useSelector((state) => state.app.AppConfig);

  // Sentry User
  useEffect(() => {
    user?.id && isProduction && Sentry.setUser({ id: user.id, email: user?.email });
    return () => {
      isProduction && Sentry.setUser(null);
    };
  }, [user?.id]);

  // Zendesk
  useEffect(() => {
    if (isProduction && user && !isMemberArea && tenantConstants.ZENDESK) {
      const loadZendesk = () => {
        if (window.zE) {
          window.zE(() => {
            window.zE.setLocale(locale);
            window.zE('webWidget', 'updateSettings', {
              webWidget: {
                launcher: { mobile: { labelVisible: true } },
                chat: { connectOnPageLoad: false },
                position: { horizontal: 'left', vertical: 'bottom' },
              },
            });
            window.zE('webWidget', 'prefill', {
              name: { value: user.name, readOnly: true },
              email: { value: user.email, readOnly: !!user.email },
            });
          });
        }
      };
      // Zendesk
      const zendeskScript = document.createElement('script');
      zendeskScript.id = 'ze-snippet';
      zendeskScript.src = `${process.env.REACT_APP_ZENDESK}`;
      document.head.appendChild(zendeskScript);

      if (document.readyState === 'complete') {
        setTimeout(() => {
          loadZendesk();
        }, 1500);
      }
    }
  }, [user?.id, isMemberArea, tenantConstants.ZENDESK]);
};
export default useAppServices;
