import TenantComponents from '@components';
import tenantConstants from '@constants';
import tenantTheme from '@theme';
import { Col, Layout, Row } from 'antd';
import cx from 'clsx';
import { t } from 'i18next';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppBanner from '../components/appBanner/appBanner';
import { Button, Icon } from '../components/common';
import AuthInfo from '../components/utilities/auth-info/info';
import { useGetLocation } from '../hooks';
import { setMobile } from '../store/appSlice';
import { TENANT_KEY } from '../utility/env';
import { isMobile as isMobileFunc } from '../utility/general';
import { isWebView } from '../utility/utility';
import MenueItems from './MenueItems';
import { Div } from './style';

const { Header, Footer, Sider, Content } = Layout;

const withAdminLayout = (WrappedComponent) => (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [hideTopBar, setHideTopBar] = useState(false);
  const { isMobile, darkMode, topMenu, rtl, isMemberArea } = useSelector((state) => state.app.AppConfig);
  const { user } = useSelector((state) => state.app.loginUser);

  const dispatch = useDispatch();
  const controlRef = useRef();
  const completionModalSuccessRef = useRef();

  const headerContainerRef = useRef();
  const [minHeightOffset, setMinHeightOffset] = useState(0);

  const location = useGetLocation();
  const isDashboardPath = location.pathname.includes('/dashboard');
  const [isBannerVisible, setIsBannerVisible] = useState(
    isMobile && isDashboardPath && tenantConstants.SHOW_APP_BANNER,
  );

  const profileCompletionScore = `showCompletionModal_${user?.id}`;
  const userTruBrokerDate = `truBrokerDate_${user?.id}`;

  useEffect(() => {
    const showBanner = JSON.parse(localStorage.getItem('showBanner'));
    setIsBannerVisible(showBanner && isMobile && isDashboardPath && tenantConstants.SHOW_APP_BANNER);
  }, [isMobile, isDashboardPath]);

  const isTruBrokerForFirstTime = () => {
    return (
      tenantConstants.TRU_BROKER_ENABLED &&
      user?.is_tru_broker &&
      JSON.parse(localStorage.getItem(userTruBrokerDate)) != user?.tru_broker_start_date
    );
  };
  const showProfileSuccessPopUp = () => {
    return (
      user?.profile_completion?.score == 100 &&
      JSON.parse(localStorage.getItem(profileCompletionScore)) != user?.profile_completion?.score &&
      !isTruBrokerForFirstTime()
    );
  };

  const handleTruBrokerSuccessIntimation = () => {
    if (isTruBrokerForFirstTime()) {
      completionModalSuccessRef?.current && completionModalSuccessRef?.current?.showModal('isTruBroker');
      localStorage.setItem(userTruBrokerDate, JSON.stringify(user?.tru_broker_start_date));
    }
    localStorage.setItem(userTruBrokerDate, JSON.stringify(user?.tru_broker_start_date));
  };

  useEffect(() => {
    if (tenantConstants.PROFILE_COMPLETION_APPLICABLE && !isMemberArea && !isWebView()) {
      if (showProfileSuccessPopUp()) {
        completionModalSuccessRef?.current && completionModalSuccessRef?.current?.showModal('isProfileCompleted');
        localStorage.setItem(profileCompletionScore, JSON.stringify(user?.profile_completion?.score));
      }
      localStorage.setItem(profileCompletionScore, JSON.stringify(user?.profile_completion?.score));
      handleTruBrokerSuccessIntimation();
    }
  }, [user]);

  const updateDimensions = () => {
    setCollapsed(true);
    dispatch(setMobile(isMobileFunc()));
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    document.addEventListener('mousedown', handleClickOutside);
    updateDimensions();
    return () => {
      window.removeEventListener('resize', updateDimensions);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    if (headerContainerRef?.current) {
      setMinHeightOffset(headerContainerRef?.current?.offsetHeight);
    }
  }, [headerContainerRef]);

  const handleClickOutside = (event) => {
    if (controlRef && !controlRef?.current?.contains(event.target) && !collapsed) {
      setCollapsed(true);
    }
  };

  const handleBannerClose = () => {
    setIsBannerVisible(false);
    localStorage.setItem('showBanner', JSON.stringify(false));
  };

  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };

  const toggleCollapsedMobile = () => {
    if (isMobileFunc()) {
      setCollapsed((prev) => !prev);
    }
  };

  const footerStyle = {
    padding: '12px 16px',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '14px',
    background: 'rgba(255, 255, 255, .90)',
    width: '100%',
    boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
  };

  const SideBarStyle = {
    margin: '0',
    paddingBlock: '15px 0',
    overflowY: 'auto',
    position: 'fixed',
    top: isBannerVisible ? 143 : 74,
    insetInlineStart: 0,
    bottom: 0,
    zIndex: 998,
  };

  const renderHeader = () => (
    <Header
      style={{
        position: 'fixed',
        top: isBannerVisible ? 66 : 0,
        insetInline: 0,
      }}
      ref={headerContainerRef}
    >
      <Row align="middle" justify="space-between">
        <Col lg={!topMenu ? 4 : 3} sm={6} xs={14}>
          <div className="align-center-v navbar-brand">
            {!topMenu || isMobile ? (
              <Button type="link" onClick={toggleCollapsed} style={{ '--btn-content-color': '#000' }}>
                <Icon icon={`CgMenu${rtl ? (collapsed ? 'Right' : 'Left') : collapsed ? 'Left' : 'Right'}`} size={24} />
              </Button>
            ) : null}

            <Link className={cx(topMenu && !isMobile && 'top-menu', 'striking-logo')} to="/">
              <TenantComponents.Logo rtl={rtl} />
            </Link>
          </div>
        </Col>

        {isMobile ? (
          <Col md={0} sm={18} xs={10}>
            <div className="mobile-action">
              <AuthInfo />
            </div>
          </Col>
        ) : (
          <Col lg={6} md={10} sm={0} xs={0}>
            <AuthInfo />
          </Col>
        )}
      </Row>
    </Header>
  );

  return (
    <Div
      darkMode={darkMode}
      theme={{ ...tenantTheme, topMenu: !!isMemberArea, isMobile }}
      style={{ paddingBlockStart: isBannerVisible ? '72px' : 0 }}
    >
      {isBannerVisible && (
        <AppBanner
          style={{ position: 'fixed', top: 0, insetInline: 0, zIndex: 1000 }}
          handleClose={handleBannerClose}
        />
      )}
      <Layout>
        {isMemberArea ? (
          <div ref={headerContainerRef}>
            <TenantComponents.Layout.Header />
          </div>
        ) : (
          !isWebView() && renderHeader()
        )}

        {!isMemberArea &&
          (!topMenu || isMobile ? (
            <div ref={controlRef}>
              <ThemeProvider theme={tenantTheme?.darkTheme}>
                <Sider
                  width={260}
                  style={SideBarStyle}
                  collapsed={collapsed}
                  theme={!darkMode ? 'light' : 'dark'}
                  onMouseEnter={() => setCollapsed(false)}
                  onMouseLeave={() => setCollapsed(true)}
                >
                  <MenueItems
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsedMobile}
                    handleTopBarSticky={(hide = false) => setHideTopBar(hide)}
                  />
                </Sider>
              </ThemeProvider>
            </div>
          ) : null)}

        <Layout className="atbd-main-layout" style={{ '--min-height-offset': `${minHeightOffset}px` }}>
          <Content>
            <WrappedComponent {...props} isMobile={isMobile} />
          </Content>

          {isMemberArea ? (
            <TenantComponents.Layout.Footer />
          ) : (
            <Footer className="admin-footer" style={footerStyle}>
              <Row>
                <Col xs={24} align="center">
                  <span className="admin-footer__copyright">
                    © {new Date().getFullYear()} –{' '}
                    {TENANT_KEY === 'zameen' ? t('Powered by Zameen.com') : t('All Rights Reserved')}
                  </span>
                </Col>
              </Row>
            </Footer>
          )}
        </Layout>
        {tenantConstants.PROFILE_COMPLETION_APPLICABLE && !isMemberArea && (
          <TenantComponents.ProfileCompletionCongratsModal ref={completionModalSuccessRef} />
        )}
      </Layout>
    </Div>
  );
};

export default withAdminLayout;
