import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Heading, LoaderWrapper } from '../../../../components/common';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Row } from 'antd';
import { JSONFormStyled } from '../../../../components/common/json-form/styled';
const OwnerProfile = ({ formFields, formValues, agencyOwnerFormRef, handleSubmit, postOwnerLoading, loading }) => {
  const { t } = useTranslation();
  const isMobile = useSelector((state) => state.app.AppConfig.isMobile);
  return (
    <Card bodyStyle={{ padding: isMobile ? 16 : 40 }}>
      <Heading as={isMobile ? 'h5' : 'h3'} className={isMobile ? 'mb-20' : 'mb-0'}>
        {t('CEO/Owner Profile')}
      </Heading>
      {!isMobile && <Divider style={{ margin: '28px 0 40px' }} />}
      <LoaderWrapper loading={loading}>
        <JSONFormStyled
          key={'agency-owner'}
          fields={formFields}
          formFieldValues={formValues}
          ref={agencyOwnerFormRef}
          onSubmitForm={(values) => {
            handleSubmit(values, true);
          }}
          noOfContentColumns={2}
        />
      </LoaderWrapper>

      <Row>
        <Col xs={24} align="end" style={{ maxWidth: 880, margin: '18px auto 0' }}>
          <Button
            type="primary"
            size="large"
            disabled={!formValues || postOwnerLoading}
            onClick={() => {
              agencyOwnerFormRef.current && agencyOwnerFormRef.current.submitForm();
            }}
            style={{
              paddingInline: 60,
            }}
            block={isMobile ? true : false}
          >
            {t('Save Changes')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
export default OwnerProfile;
