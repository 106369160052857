import tenantConstants from '@constants';
import tenantTheme from '@theme';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import React, { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import { useAppInit } from './hooks';
import MainRouter from './router';
import './static/less/antd-customized.less';
import './static/less/style.less';
import './static/less/utils.less'; // Should be at the end of CSS loading order
import { isLiteExperienceURL } from './utility/general';

const App = () => {
  const { locale, rtl } = useAppInit();

  const getLangBasePath = () => {
    const pathDirectories = window.location.pathname.split('/');
    if (pathDirectories.length > 1) {
      const pathLocale = pathDirectories[1];
      const lang = tenantConstants.LANGUAGES.find((e) => e.key == pathLocale) || {};
      return lang.key || '';
    }
  };

  const appTheme = useMemo(
    () => ({
      hashed: false,
      cssVar: { key: 'styleProfolio' },
      rtl,
      darkMode: { ...tenantTheme?.darkTheme },
      ...tenantTheme.themeTokens,
      token: {
        ...tenantTheme.themeTokens.token,
        ...(isLiteExperienceURL() ? { fontFamily: tenantConstants.FONT_FAMILY_LITE } : {}),
      },
    }),
    [tenantConstants],
  );

  return (
    <ConfigProvider locale={locale} direction={rtl ? 'rtl' : 'ltr'} theme={appTheme}>
      <ThemeProvider theme={{ ...tenantTheme, rtl }}>
        <ErrorBoundary>
          <BrowserRouter basename={getLangBasePath()}>
            <MainRouter />
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default App;
