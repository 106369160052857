import tenantData from '@data';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderWrapper, notification } from '../../components/common';
import { strings } from '../../constants/strings';
import Algolia from '../../services/algolia';
import { JSONFormStyled } from '../common/json-form/styled';
import { Modal } from '../common/modals/antd-modals';
import {
  useAddAgencyStaffUserMutation,
  useGetAgencyUserDetailsQuery,
  useUpdateAgencyUserMutation,
} from '../../apis/agency';

export const UserForm = ({ isVisible, setIsVisible, agencyId, userId, isAdmin }) => {
  const { t } = useTranslation();
  const [cities, setCities] = useState(false);
  const formRef = useRef();

  const {
    data: formData,
    isFetching,
    isLoading,
    error,
    refetch,
  } = useGetAgencyUserDetailsQuery(userId, { skip: !isVisible || !userId, refetchOnMountOrArgChange: true });

  const [updateUser, { isLoading: updating }] = useUpdateAgencyUserMutation();
  const [addUser, { isLoading: addingUser }] = useAddAgencyStaffUserMutation();

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    const queryObject = Algolia.getAllCities();
    const response = await Algolia.getLocationsIndex().search('', queryObject);
    setCities(
      response.hits.map((e) => {
        return { id: e.location_id, name: e.title.en };
      }),
    );
  };

  const fields = useMemo(() => tenantData.agencyStaffUserFormFields(formData, cities), [tenantData, formData, cities]);

  const onCloseModal = () => {
    setIsVisible(false);
    formRef?.current && formRef?.current?.resetForm();
  };

  const handleOk = () => {
    formRef.current && formRef.current.submitForm();
  };

  const handleDetailsSubmit = async (values) => {
    const response = userId ? await updateUser({ userId, agencyId, values }) : await addUser({ agencyId, ...values });
    if (response.error) {
      notification.error(response.error);
    } else {
      onCloseModal();
    }
  };

  return (
    <Modal
      type="primary"
      title={userId ? t(strings.edit_user) : t(strings.add_user)}
      visible={isVisible}
      okText="Confirm"
      okButtonProps={{ type: 'primary' }}
      loading={isLoading || updating || addingUser}
      onOk={handleOk}
      onCancel={onCloseModal}
      width={990}
    >
      <LoaderWrapper loading={isFetching && !isLoading}>
        <JSONFormStyled
          fields={fields}
          formFieldValues={formData}
          ref={formRef}
          onSubmitForm={handleDetailsSubmit}
          loading={isLoading}
          fetchError={error}
          retryButtonLoading={!formData && isLoading}
          onRetry={refetch}
          noOfContentColumns={2}
        />
      </LoaderWrapper>
    </Modal>
  );
};
